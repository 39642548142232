import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {InactivityService} from '../web/inactivity.service';
import {ConfigService} from '../web/config.service';
import {HttpHeaders} from '@angular/common/http';
import {StorageService} from '../web/storage.service';
import {ApiBaseService} from './api-base.service';
import {NotificationService} from '../web/notification.service';


@Injectable({
  providedIn: 'root'
})

export class MediprocityApiService extends ApiBaseService {
  constructor(
    httpService: HttpService,
    inactivityService: InactivityService,
    storageService: StorageService,
    notificationService: NotificationService
) {
    super(httpService, inactivityService, storageService, notificationService);
    this.basePath = 'https://api.mediprocity.com/api';
  }

  protected createHeaders(): HttpHeaders{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', `${this.storageService.getSessionItem('mediAccessToken')}`);
    return headers;
  }
}
