export const environment = {
  production: true,
  deployAssetsPath: 'FrameworkVision',
  baseHref: '/FrameworkVision/',
  applicationName: 'FrameworkVision',
  userGuidePath: 'assets/user-guide/FrameworkVision User Guide.pdf',
  appConfigPath: 'assets/app-config.json',
  siteDefaults: {
    loginBackgroundColor: '#ff9933',
  },
  woltersKluwerEulaPath: '/assets/eula/wolters-kluwer-eula.pdf'
};
