import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DxScrollViewModule } from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SidebarButtonComponent } from './components/sidebar-button/sidebar-button.component';
import { SidebarCategoryComponent } from './components/sidebar-category/sidebar-category.component';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    SidebarButtonComponent,
    SidebarCategoryComponent,
    SidebarComponent,
    SidebarLinkComponent,
    SidebarMenuComponent,
    TopbarComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    SharedModule,
    CommonModule,
    DxScrollViewModule
  ],
})
export class MainLayoutModule {}
