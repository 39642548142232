import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  @Input() public header = '';
  @Input() public subHeader = '';
  @Input() public showModal = false;
  @Input() public danger = false;
  @Input() public size = 'sm';
  @Input() public closeModalAfterClick = true;
  @Input() public preventForceClose = false;
  @Output() public modalClose = new EventEmitter<any>();
  @ViewChild('modal') public modalElement: ElementRef;
  @ViewChild('modalBody') public modalBodyElement: ElementRef;

  public ngAfterViewInit(): void {
    if(this.closeModalAfterClick){
      this.modalElement.nativeElement.addEventListener('click', (event) => {
        if (!this.modalBodyElement.nativeElement.contains(event.target)) {
          this.closeModal();
        }
      });
    }
  }

  public closeModal(): void {
    if (!this.preventForceClose) {
      this.showModal = false;
    }
    this.modalClose.emit();
  }
}
