import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent implements AfterViewInit {
  @Input() public alignLeft = true;
  @Input() public hideOnClick = true;
  @ViewChild('header') public headerElement: ElementRef;
  public showDropdown = false;
  public clickedInside = false;
  public headerClickedWhenOpen = false;

  public ngAfterViewInit(): void {
    this.headerElement.nativeElement.addEventListener('click', (event: any) => {
      if (this.showDropdown) {
        this.headerClickedWhenOpen = true;
      }
    });
  }

  @HostListener('click')
  public clickInside(): void {
   this.clickedInside = true;
   if (this.headerClickedWhenOpen) {
     this.showDropdown = false;
     this.headerClickedWhenOpen = !this.headerClickedWhenOpen;
    } else if (this.showDropdown && this.hideOnClick){
      this.showDropdown = false;
    } else {
     this.showDropdown = true;
   }
  }

  @HostListener('document:click')
  public clickout(): void {
    if (!this.clickedInside) {
      this.showDropdown = false;
    }
    this.clickedInside = false;
  }
}
