import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/models/web/menu.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuUtilityService {

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {}

  public parseMenuData(menuData: Menu[], useAccess: boolean = false): Menu[] {
    let parentRoutes = menuData.filter(item => !item.parentId);

    this.parseNestedPages(menuData, useAccess);

    menuData.forEach(item => {
      if (item.parentId) {
        parentRoutes.forEach(parent => {
          if (parent.id === item.parentId) {
            if (!parent.children) {
              parent.children = [];
            }
            if (useAccess) {
              if (item.readAccess || item.writeAccess) {
                parent.children.push(item);
              }
            } else {
              parent.children.push(item);
            }
          }
        });
      }
    });

    if (useAccess) {
      parentRoutes = parentRoutes.filter(p => p.id === 1 || p.children.length > 0);
    }

    return parentRoutes;
  }

  public hasWriteAccess(nestedPagePath: string = undefined): boolean {
    const target = this.router.url.split('/');
    const menus: Menu[] = JSON.parse(this.storageService.getSessionItem('access'));
    if(nestedPagePath){
      return menus && menus.some(m => m.menuPath === target[1] && m.children && m.children.some(
        c => c.menuPath === target[2] && c.children && c.children.some(n => n.menuPath === nestedPagePath && n.writeAccess)));
    }
    else{
      return menus && menus.some(m => m.menuPath === target[1] && m.children && m.children.some(c => c.menuPath === target[2] && c.writeAccess));
    }
  }

  //Finds nested pages and places them under parent page.
  //Also adjusts parent's access based on nested pages.
  //Currently only have nested pages for View/Edit Profile but made this generic
  private parseNestedPages(menuData: Menu[], useAccess: boolean) {
    const subPages = menuData.filter(item => item.menuType === 'nest');
    const parentIds = new Set(subPages.map(item => item.parentId));
    const parents = menuData.filter(item => parentIds.has(item.id));

    parents.forEach(parent =>{
      parent.children = subPages.filter( item => item.parentId === parent.id
        && (!useAccess || (item.readAccess || item.writeAccess)));

      //Calc parents access based on children
      if(
        (useAccess && parent.children.length === 0)
        || (!useAccess && parent.children.every(p => !p.writeAccess && !p.readAccess))
      ){
        parent.readAccess = parent.writeAccess = false;
      }
      else{
        parent.readAccess = false;
        parent.writeAccess = true;
      }
    });
  }
}
