<div class="loading-wrapper">
  <div class="loading">
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
    <div class="loading__dot"></div>
  </div>
</div>