import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StorageService} from '../../../../services/web/storage.service';
import {ModalComponent} from '../modal/modal.component';

@Component({
  selector: 'app-mediprocity-widget',
  templateUrl: './mediprocity-widget.component.html',
  styleUrls: ['mediprocity-widget.component.scss'],
})

export class MediprocityWidgetComponent implements OnInit, OnChanges {
  @Input() public modal: ModalComponent;
  @Input() public triggerReload: boolean;

  constructor(
    private storageService: StorageService,
  ) {
  }

  public ngOnInit(): void {
    this.initializeWidget();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.triggerReload){
      this.initializeWidget();
      this.storageService.setSessionItem('mediReloadWidget', 'false');
    }
  }

  private initializeWidget(): void {
    const options = {
      container: '#mediprocity-widget\\.container',
      token: this.storageService.getSessionItem('mediAccessToken'),
      url: 'https://client.mediprocity.com/'
    };

    const widget = this.mediprocityWidget(options);
    widget.init();
  }

  // The following code is from mediprocity and is hosted at https://client.mediprocity.com/assets/widgets/embed.js
  // There is a tech debt ticket around refactoring this code to better meet our standards. WM-1211
  private mediprocityWidget(options) {
    return({
      options: options,

      init() {
        this.createIframe();
      },

      login(contentWindow) {
        if(options.token) {
          contentWindow.postMessage(this.options, this.options.url);
        }
      },

      createIframe() {
        const self = this;
        const container = document.querySelector(this.options.container);
        this.cleanupIframes();
        if(!container) {
          return;
        }
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', this.getLoginUrl());
        iframe.setAttribute('style', 'width:100%; height:80vh;');
        iframe.setAttribute('id', 'mediprocity-frame');
        iframe.setAttribute('frameborder', '0');
        container.appendChild(iframe);
        iframe.onload = () => { self.login(iframe.contentWindow); };
      },

      cleanupIframes() {
        const iframes = document.querySelectorAll('iframe#mediprocity-frame');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < iframes.length; i++) {
          iframes[i].parentNode.removeChild(iframes[i]);
        };
      },

      getLoginUrl() {
        return this.getHostUrl() + '/login?widget=true';
      },

      getHostUrl() {
        const link = document.createElement('a');
        link.href = this.options.url;
        return link.origin || (link.protocol + '//' + link.host);
      }
    });
  };
}
