import { Injectable } from '@angular/core';
import { PendingAllergy } from '../../models/condition/pending-allergy.model';

@Injectable({
  providedIn: 'root'
})
export class StringUtils {

  public static createStringFromSymptoms(allergy: PendingAllergy): string {
    const symptoms = {
      skin: {
        allergyPresent: allergy.rash,
        text: 'Skin Rash/Hives'
      },
      asthma: {
        allergyPresent: allergy.asthma,
        text: 'Asthma'
      },
      nausea: {
        allergyPresent: allergy.nausea,
        text: 'Nausea/Vomiting'
      },
      shock: {
        allergyPresent: allergy.shock,
        text: 'Shock/Unconsciousness'
      },
      anemia: {
        allergyPresent: allergy.anemia,
        text: 'Anemia'
      },
      other: {
        allergyPresent: allergy.other,
        text: allergy.otherDescription
      }
    };

    let symptomString = '';
    Object.keys(symptoms).forEach(key => {
      const condition: any = symptoms[key];
      if ((condition.allergyPresent !== 'False' && condition.allergyPresent) && condition.text) {
        symptomString += condition.text + ', ';
      }
    });

    return symptomString.slice(0, symptomString.length - 2);
  }

  public static sortByStringProperty(property: string): (a: any, b: any) => number {
    return (a, b) => {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  public static genderAbbreviationToString(abbreviation: string): string {
    if (abbreviation === 'M') {
      return 'Male';
    } else if (abbreviation === 'F') {
      return 'Female';
    } else {
      return '';
    }
  }

  public static isNullUndefinedOrWhitespace(str: any): boolean {
    return str === undefined
      || str === null
      || (typeof str === 'string' && str.match(/^ *$/) !== null);
  }

  public static coerceNullOrWhitespaceToUndefined(s: string): string {
    return StringUtils.isNullUndefinedOrWhitespace(s) ? undefined : s.trim();
  }

  public static capitalizeFirstLetters(s: string): string {
    return s.toLowerCase()
      .split(' ')
      .map((x) => x.charAt(0).toUpperCase() + x.substring(1))
      .join(' ');
  }

  public static removeWhiteSpace(s: string): string {
    return s.replace(/\s/g, '');
  }

  public static removeCarriageReturns(str: string): string {
    if (str) {
      const splitString = str.split('\r');
      return splitString.join('');
    }
    return str;
  }

  public static prependCarriageReturnsToNewLines(str: string): string {
    if (str) {
      const splitText = str.split('\n');
      return splitText.join('\r\n');
    }
    return str;
  }
}
