import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UriUtils {
  public static addPathParamsToURI(url: string, pathParams: any): string {
    for (const param in pathParams) {
      url = url.replace(`{${param}}`, encodeURIComponent(pathParams[param].toString()));
    }
    return url;
  }
}
