import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Menu } from 'src/app/models/web/menu.model';
import { VisionApiService } from 'src/app/services/api/vision-api.service';
import { MenuUtilityService } from 'src/app/services/web/menu-utility.service';
import { VisionURIs } from '../../endpoints/visionURIs';
import { FunctionalityPermission } from '../../models/role/functionality-permission.model';
import { NotificationService } from '../web/notification.service';
import { StorageService } from '../web/storage.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private api: VisionApiService,
    private menuUtilService: MenuUtilityService,
    private storageService: StorageService,
    private notificationService: NotificationService
  ) { }

  public getAllMenus(isFacilityAdmin: boolean): Observable<Menu[]> {
    const pathParams = { isFacilityAdmin: isFacilityAdmin };
    return this.api.get<Menu[]>(VisionURIs.getAllMenus, null, true, pathParams)
      .pipe(
        map(res => this.menuUtilService.parseMenuData(res, true)),
        tap(routes => this.storageService.setSessionItem('access', JSON.stringify(routes)))
      );
  }

  public getFunctionalityPermissions(): Observable<FunctionalityPermission[]> {
    return this.api.get<FunctionalityPermission[]>(VisionURIs.functionalityPermissions)
      .pipe(
        tap(f => this.storeFunctionalityPermissionsByVerb(f)),
        catchError(() => {
          this.notificationService.error('Error retrieving functionality permissions for current user.', 'Error');
          return of(undefined);
        })
      );
  }

  private storeFunctionalityPermissionsByVerb(perms: FunctionalityPermission[]) {
    const getPermissions = perms.filter(p => p.verb && p.verb.toLowerCase() === 'get');
    const postPermissions = perms.filter(p => p.verb && p.verb.toLowerCase() === 'post');
    const deletePermissions = perms.filter(p => p.verb && p.verb.toLowerCase() === 'delete');
    const putPermissions = perms.filter(p => p.verb && p.verb.toLowerCase() === 'put');
    const patchPermissions = perms.filter(p => p.verb && p.verb.toLowerCase() === 'patch');
    const nullVerbPermissions = perms.filter(p => p.verb === null);

    if (getPermissions.length > 0) {
      this.storageService.setSessionItem('getPermissions', JSON.stringify(getPermissions));
    }
    if (postPermissions.length > 0) {
      this.storageService.setSessionItem('postPermissions', JSON.stringify(postPermissions));
    }
    if (deletePermissions.length > 0) {
      this.storageService.setSessionItem('deletePermissions', JSON.stringify(deletePermissions));
    }
    if (putPermissions.length > 0) {
      this.storageService.setSessionItem('putPermissions', JSON.stringify(putPermissions));
    }
    if (patchPermissions.length > 0) {
      this.storageService.setSessionItem('patchPermissions', JSON.stringify(patchPermissions));
    }
    if (nullVerbPermissions.length > 0) {
      this.storageService.setSessionItem('nullVerbPermissions', JSON.stringify(nullVerbPermissions));
    }
  }
}
