import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-link',
  templateUrl: './sidebar-link.component.html',
  styleUrls: ['./sidebar-link.component.scss']
})

export class SidebarLinkComponent {
  @Input() public route = '';
  @Input() public id = '';
}
