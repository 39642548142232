import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, map, reduce, tap } from 'rxjs/operators';
import { MAX_PAGINATION_SIZE } from 'src/app/exports/constants';
import { IamURIs } from '../../endpoints/iamURIs';
import { FacilityAccess } from '../../models/facility/facility-access.model';
import { TotpQrCodeModel } from '../../models/user/totp-qrcode.model';
import { UpdateUserRequest } from '../../models/user/update-user-request.model';
import { User } from '../../models/user/user.model';
import { PaginationResult } from '../../models/web/pagination-result.model';
import { UserAccountMetrics } from '../../models/web/user-account-metrics.model';
import { AuthService } from '../web/auth.service';
import { NotificationService } from '../web/notification.service';
import { StorageService } from '../web/storage.service';
import { ApiResponseService } from './api-response.service';
import { VisionApiService } from './vision-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public readonly edpOwnerRole = 'EDPOWNER';
  public readonly edpAdminRole = 'EDPADMIN';
  public readonly edpUserRole = 'EDPUSER';

  constructor(
    private apiService: VisionApiService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private apiResponseService: ApiResponseService,
    private storageService: StorageService
  ) {
  }

  public getUserById(userId: string, displayError: boolean = true): Observable<User> {
    return this.apiService.get<User>(`/edp/v1/auth/facilities/user?userId=` + encodeURIComponent(userId))
      .pipe(
        catchError(err => {
          if (err.status === 403 && displayError) {
            this.notificationService.error(`You do not have access to this user's profile`, 'Error');
          } else if (displayError) {
            this.notificationService.error('Error retrieving user.', 'Error');
          }

          return of(undefined);
        })
      );
  }

  public getUsersPaginated(page: number, size: number, facilityId?: string): Observable<PaginationResult<User[]>> {
    const facilityFilter = facilityId ? `?facilityId=${encodeURIComponent(facilityId)}&` : '?';
    return this.apiService.getPagination<User[]>(
      `/edp/v1/auth/facilities/users${facilityFilter}` +
      'page=' + page +
      '&size=' + size
    )
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve users.', 'Error');
          return of(undefined);
        })
      );
  }

  public getAllUsers(facilityId?: string): Observable<User[]> {
    const facilityFilter = facilityId ? `?facilityId=${encodeURIComponent(facilityId)}&` : '?';
    return this.apiService.getPagination<User[]>(`/edp/v1/auth/facilities/users${facilityFilter}page=0&size=` + MAX_PAGINATION_SIZE)
      .pipe(
        expand(res => res.nextPage != null ? this.apiService.getPagination<User[]>(res.nextPage.replace(/.*?\/api/, '')) : EMPTY),
        map(x => x.content),
        reduce((acc: User[], curr: User[]) => acc.concat(curr)),
        catchError(() => {
          this.notificationService.error('Could not retrieve users.', 'Error');
          return of(undefined);
        })
      );
  }

  public searchUsersPaginated(page: number, size: number, excludeOwner: boolean, searchValue?: string): Observable<PaginationResult<User[]>> {
    const body = {
      firstName: searchValue ? searchValue : '',
      lastName: searchValue ? searchValue : '',
      email: searchValue ? searchValue : '',
      userName: searchValue ? searchValue : ''
    };
    let sizeQuery;
    let pageQuery;
    if (size !== undefined && !isNaN(size)) {
      sizeQuery = `size=${size}`;
      if (page !== undefined && !isNaN(page)) {
        pageQuery = `&page=${page}`;
      } else {
        pageQuery = ``;
      }
    } else {
      sizeQuery = '';
      if (page !== undefined && !isNaN(page)) {
        pageQuery = `page=${page}`;
      } else {
        pageQuery = ``;
      }
    }
    const url = `/edp/v1/auth/facilities/users/search?${sizeQuery}${pageQuery}`;
    return this.apiService.postPagination<User[]>(url, body)
      .pipe(
        map((res) => {
          //FIRST - Set only active users to visible
          // eslint-disable-next-line @typescript-eslint/dot-notation
          res.content.forEach(user => user['visible'] = user.status.toLowerCase() === 'active');

          //SECOND- Set EDPOwner's visibility to false if exclude owner flag set
          if (excludeOwner) {
            const owners = res.content.filter(user => user.roles?.includes('EDPOWNER'));
            // eslint-disable-next-line @typescript-eslint/dot-notation
            owners.forEach(user => user['visible'] = false);
          }
          return res;
        }),
        catchError((err) => {
          this.notificationService.error(err, 'Error');
          return of(undefined);
        })
      );
  }

  public getUsersDataSource(excludeOwner: boolean): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          const page = loadOptions.skip / loadOptions.take;
          return await this.searchUsersPaginated(page, (loadOptions.take + 1), excludeOwner, loadOptions.searchValue?.substring(0, 16))
            .toPromise()
            .then(response => response.content);
        },
        byKey: (key: any) => {
          if (key !== undefined && key !== '') {
            return key;
          }
        }
      })
    });
  }

  public getUserFacilityAccess(userId: string): Observable<FacilityAccess[]> {
    return this.apiService.get<FacilityAccess[]>(`/edp/v1/auth/facilities?userId=${encodeURIComponent(userId)}`)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve facility access for user', 'Error');
          return of(undefined);
        })
      );
  }

  public createUser(userData: any): Observable<any> {
    return this.apiService.post('/frameworkiam/v1/users', userData);
  }

  public updateUser(user: UpdateUserRequest): Observable<any> {
    return this.apiService.put('/frameworkiam/v1/users', user)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.notificationService.error('You do not have access to update this user.', 'Error');
          } else {
            this.notificationService.error('Error updating user.', 'Error');
          }

          return of(undefined);
        })
      );
  }

  public activateUser(userId: string): Observable<any> {
    return this.apiService.put(`/frameworkiam/v1/users/` + encodeURIComponent(userId) + `/activate`)
      .pipe(
        catchError(() => {
          this.notificationService.error('Account status could not be updated.', 'Error');
          return of(undefined);
        })
      );
  }

  public deactivateUser(userId: string): Observable<any> {
    return this.apiService.put(`/frameworkiam/v1/users/` + encodeURIComponent(userId) + `/deactivate`)
      .pipe(
        catchError(() => {
          this.notificationService.error('Account status could not be updated.', 'Error');
          return of(undefined);
        })
      );
  }

  public getUserAccountMetrics(daysStale: number): Observable<UserAccountMetrics> {
    return this.apiService.get<UserAccountMetrics>(`/frameworkiam/v1/metrics/users?daysStale=${daysStale}`)
      .pipe(
        catchError(() => {
          this.notificationService.error('User Metrics could not be returned.', 'Error');
          return of(undefined);
        })
      );
  }

  public sendResetPasswordEmail(userName: string): Observable<any> {
    return this.apiService.put(`/frameworkiam/v1/users/password/token?userName=` + encodeURIComponent(userName));
  }

  public changePassword(currentPassword: string, newPassword: string, verifyPassword: string): Observable<any> {
    const body = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: verifyPassword
    };

    return this.apiService.put(`/frameworkiam/v1/users/password`, body)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Unable to change password.');
          return of(undefined);
        })
      );
  }

  public changeUserPassword(userId: string, loggedInUserPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
    return this.apiService.put(`/frameworkiam/v1/users/${encodeURIComponent(userId)}/password`, {
      loggedInUserPassword,
      newPassword,
      confirmPassword
    })
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Could not update the user's password.`);
          return of(undefined);
        })
      );
  }

  public setMFAStatus(): Observable<any> {
    return this.apiService.patch<{ totpEnabled: string }>(IamURIs.updateUserProfile)
      .pipe(
        tap(res => {
          this.storageService.setSessionItem('mfaEnabled', res.totpEnabled);
        }),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'User profile could not be retrieved.');
          return of(undefined);
        })
      );
  }

  public getMFAQrCode(): Observable<TotpQrCodeModel> {
    return this.apiService.get<TotpQrCodeModel>(IamURIs.mfaTotp)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not get Multi-Factor Authentication (MFA) QR Code.');
          return of(undefined);
        })
      );
  }

  public enableMFA(secret: string, code: string): Observable<any> {
    const payload = {
      secret,
      code
    };
    return this.apiService.put(IamURIs.mfaTotp, payload).pipe(
      catchError(err => {
        this.apiResponseService.showApiErrorOrDefault(err, 'Could not enable multi-factor authentication (MFA).');
        return of(err);
      })
    );
  }

  public disableMFA(): Observable<any> {
    return this.apiService.delete(IamURIs.mfaTotp)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not disable multi-factor authentication (MFA).');
          return of(undefined);
        })
      );
  }
}
