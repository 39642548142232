import { Routes } from '@angular/router';
import { MainLayoutComponent } from './modules/main-layout/components/main-layout/main-layout.component';
import { LoadingIndicatorComponent } from './modules/shared/components/loading-indicator/loading-indicator.component';
import { dashboardGuard } from './services/guards/dashboard-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'encrypt',
    loadChildren: () => import('./modules/encrypt/encrypt.module').then(m => m.EncryptModule)
  },
  {
    path: 'full-displays',
    loadChildren: () => import('./modules/full-displays/full-displays.module').then(m => m.FullDisplaysModule)
  },
  {
    path: 'loading',
    component: LoadingIndicatorComponent
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [dashboardGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/home/home.module').then(
            m => m.HomeModule
          ),
      },
      {
        path: 'site-management',
        loadChildren: () =>
          import('./modules/site-management/site-management.module').then(
            m => m.SiteManagementModule
          )
      },
      {
        path: 'resident-maintenance',
        loadChildren: () =>
          import('./modules/resident-maintenance/residence-maintenance.module').then(
            m => m.ResidenceMaintenanceModule
          )
      },
      {
        path: 'medical-reports',
        loadChildren: () =>
          import('./modules/medical-reports/medical-reports.module').then(
            m => m.MedicalReportsModule
          )
      },
      {
        path: 'facility-reports',
        loadChildren: () =>
          import('./modules/facility-reports/facility-reports.module').then(
            m => m.FacilityReportsModule
          )
      },
      {
        path: 'utilities',
        loadChildren: () =>
          import('./modules/utilities/utilities.module').then(
            m => m.UtilitiesModule
          )
      },
      {
        path: 'rx-management',
        loadChildren: () =>
          import('./modules/rx-management/rx-management.module').then(
            m => m.RxManagementModule
          )
      }
    ]
  }
];
