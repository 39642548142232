<form [formGroup]="mediprocityLinkForm">
  <div id="mediprocity-link.linked-user-section" *ngIf="linkedUsername" class="current-link-section margin-top">
      <label class="input-container__label">
        Linked User
        <span class="margin-left margin-top--xs italic padding-right">{{linkedUsername}}</span>
      </label>
    <div class="padding-right">
      <button id="mediprocity-link.delete-link" class="btn btn--danger btn--sm btn--min-content"
              (click)="deleteLinkedAccount()">
        Delete Link
      </button>
    </div>
  </div>
  <hr id="mediprocity-link.divider" *ngIf="linkedUsername" class="margin-bottom margin-top"/>
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        Username
        <input id="mediprocity-link.username" class="input-container__field"
          formControlName="username">
        <app-control-messages id="mediprocity-link.username-required" class="input-container__error"
          [control]="mediprocityLinkForm.get('username')">
        </app-control-messages>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        Password
        <input id="mediprocity-link.password" type="password" class="input-container__field"
          formControlName="password">
        <app-control-messages id="mediprocity-link.password-required" class="input-container__error"
          [control]="mediprocityLinkForm.get('password')">
        </app-control-messages>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        Encryption Key
        <input id="mediprocity-link.encryption-key" type="password" class="input-container__field"
          formControlName="encryptionKey">
        <app-control-messages id="mediprocity-link.encryption-key-required" class="input-container__error"
          [control]="mediprocityLinkForm.get('encryptionKey')">
        </app-control-messages>
      </label>
    </div>
  </div>
</form>
<div class="row row--end">
  <button id="mediprocity-link.close" class="btn btn--tertiary" (click)="close()">
    Close
  </button>
  <button id="mediprocity-link.link" class="btn btn--primary" [ngClass]="{'disabled': !mediprocityLinkForm.valid}"
    [disabled]="!mediprocityLinkForm.valid" (click)="linkMediprocity()">
    Link Account
  </button>
</div>
