export class PaginationResult<T> {
  public currentCount: number;
  public totalCount: number;
  public previousPage: string;
  public nextPage: string;
  public content: T;

  constructor(currentCount: number, totalCount: number, previousPage: string, nextPage: string, content: T) {
    this.currentCount = currentCount;
    this.totalCount = totalCount;
    this.previousPage = previousPage;
    this.nextPage = nextPage;
    this.content = content;
  }
}
