import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportViewerService {
  constructor(
    private router: Router
  ) { }

  public showReport(reportObservable: Observable<Blob>): void {
    // The window needs opened right away to avoid pop up blockers
    const url = this.router.serializeUrl(this.router.createUrlTree([environment.baseHref + 'loading']));
    const reportWindow = window.open(url, '_blank');

    reportObservable.subscribe(pdf => {
      if (pdf !== undefined) {
        const fileUrl = URL.createObjectURL(pdf);
        reportWindow.location = fileUrl as any;
      } else {
        // Close the window in the case of an API error
        reportWindow.close();
      }
    });
  }

  public openWindow(pdfUrl: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([environment.baseHref + 'loading']));
    const reportWindow = window.open(url, '_blank');

    reportWindow.location = pdfUrl as any;
  }
}
