import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FacilityAccess } from 'src/app/models/facility/facility-access.model';
import { FACILITY_ADMIN } from '../../../../exports/constants';
import { StorageService } from '../../../../services/web/storage.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})

export class SidebarMenuComponent {
  @Input() public menuItems: any[] = [];
  @Input() public facilities: FacilityAccess[];
  @Input() public selectedFacility: FacilityAccess;
  @Output() public facilityChange = new EventEmitter<FacilityAccess>();

  constructor(private storageService: StorageService) { }

  public changeFacility(): void {
    this.facilityChange.emit(this.selectedFacility);
  }

  public showMenuItem(menuItem: any): boolean {
    switch (menuItem.title) {
      case 'Home':
        return true;
      case 'Site Management':
        return this.isAdmin();
      default:
        return this.facilities?.length > 0;
    }
  }

  public showSubMenuItem(childItem: any): boolean {
    switch (childItem.title) {
      case 'Roles & Permissions':
        return this.isAdmin() || this.isFacilityAdmin();

      default:
        return true;
    }
  }

  private isAdmin(): boolean {
    return this.storageService.getSessionItem('edpOwner') === 'true' ||
      this.storageService.getSessionItem('edpAdmin') === 'true';
  }

  private isFacilityAdmin(): boolean {
    return this.storageService.getSessionItem('currentFacilityAccess') === FACILITY_ADMIN;
  }
}
