import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../../infrastructure/custom-http-param-encoder';
import { PaginationResult } from '../../models/web/pagination-result.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getPagination<T>(url: string, headers: HttpHeaders, params: any = null): Observable<PaginationResult<T>> {
    const options = this.setHttpRequestOptions(headers, params);

    return this.http.get<T>(url, options)
      .pipe(
        map((response: any) =>
          new PaginationResult<T>(
            response.headers.get('Pagination-Count'),
            response.headers.get('Pagination-Total'),
            response.headers.get('Pagination-Previous'),
            response.headers.get('Pagination-Next'),
            response.body
          )
        )
      );
  }

  public get<T>(url: string, headers: HttpHeaders, params: any = null): Observable<T> {
    const options = this.setHttpRequestOptions(headers, params);

    return this.http.get<T>(url, options)
      .pipe(map((response: any) => response.body));
  }

  public getBlob(url: string, headers: HttpHeaders, params: any = null): Observable<Blob> {
    const options = {
      ...this.setHttpRequestOptions(headers, params),
      responseType: 'blob'
    };

    return this.http.get<any>(url, options)
      .pipe(map((response: any) => response.body));
  }

  public postPagination<T>(url: string, headers: HttpHeaders, body: any = null, params: any = null): Observable<PaginationResult<T>> {
    const options = this.setHttpRequestOptions(headers, params);

    return this.http.post<T>(url, JSON.stringify(body || {}), options)
      .pipe(
        map((response: any) =>
          new PaginationResult<T>(
            response.headers.get('Pagination-Count'),
            response.headers.get('Pagination-Total'),
            response.headers.get('Pagination-Previous'),
            response.headers.get('Pagination-Next'),
            response.body
          )
        )
      );
  }

  public post<T>(url: string, headers: HttpHeaders, body: any = null, params: any = null): Observable<T> {
    const options = this.setHttpRequestOptions(headers, params);

    return this.http.post<T>(url, JSON.stringify(body || {}), options)
      .pipe(map((response: any) => response.body));
  }

  public put<T>(url: string, headers: HttpHeaders, body: any = null): Observable<T> {
    const options = this.setHttpRequestOptions(headers);

    return this.http.put<T>(url, JSON.stringify(body || {}), options)
      .pipe(map((response: any) => response.body));
  }

  public patch<T>(url: string, headers: HttpHeaders, body: any = null): Observable<T> {
    const options = this.setHttpRequestOptions(headers);

    return this.http.patch(url, JSON.stringify(body || {}), options)
      .pipe(map((response: any) => response.body));
  }

  public delete<T>(url: string, headers: HttpHeaders, body: any = null, params: any = null): Observable<T> {
    const options = this.setHttpRequestOptions(headers, params);
    options.body = body;

    return this.http.delete<T>(url, options)
      .pipe(map((response: any) => response.body));
  }

  public postDownload<T>(url: string, headers: HttpHeaders, body: any = null): Observable<T> {
    const options = {
      ...this.setHttpRequestOptions(headers),
      responseType: 'blob'
    };

    return this.http.post(url, JSON.stringify(body || {}), options)
      .pipe(
        map((response: any) => response.body),
        catchError(error => {
          // An issue in Angular results in the HttpErrorResponse.Error property here being a blob rather than JSON
          // See: https://github.com/angular/angular/issues/19888
          if (error instanceof HttpErrorResponse && error.error instanceof Blob && error.error.type === 'application/json') {
            return new Promise<any>((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e: Event) => {
                try {
                  const msg = JSON.parse((e.target as any).result);
                  reject(new HttpErrorResponse({
                    error: msg,
                    headers: error.headers,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url
                  }));
                } catch (exception) {
                  reject(exception);
                }
              };
              reader.readAsText(error.error);
            });
          }

          return throwError(error);
        })
      );
  }

  private setHttpRequestOptions(headers: HttpHeaders, params?: any): any {
    const options: any = {
      headers,
      observe: 'response'
    };

    if (params) {
      options.params = this.setHttpParams(params);
    }

    return options;
  }

  private setHttpParams(query: any): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    for (const key in query) {
      if (query[key] && query.hasOwnProperty(key)) {
        if (Array.isArray(query[key])) {
          query[key].forEach(value => {
            params = params.append(key, value);
          });
        }
        else {
          params = params.append(key, query[key]);
        }
      }
    }
    return params;
  }
}
