<dx-scroll-view class="sidebar-scroll-view" [useNative]="false">
  <div class="sidebar">
    <select id="sidebar.change-facility" data-cy="sidebar-change-facility" class="facility-select"
            [(ngModel)]="selectedFacility" (ngModelChange)="changeFacility()">
      <option *ngFor="let facility of facilities" [ngValue]="facility">{{facility.facilityName}}</option>
    </select>
    <div *ngFor="let menuItem of menuItems">
      <!-- If it is a single link -->
      <ng-container *ngIf="menuItem.menuType === 'link'">
        <a *ngIf="showMenuItem(menuItem)" routerLink="/{{menuItem.menuPath}}" class="single-link"
           routerLinkActive="single-link--selected">
          <i class="material-icons single-link__icon">{{menuItem.iconType}}</i>
          <span class="single-link__title">{{menuItem.title}}</span>
        </a>
      </ng-container>
      <!-- If it has a submenu -->
      <ul class="sidebar__block" *ngIf="menuItem.menuType === 'sub'">
        <app-sidebar-category *ngIf="showMenuItem(menuItem)" id="sidebar.{{menuItem.title}}"
                              data-cy="sidebar-{{menuItem.title}}" title="{{menuItem.title}}" icon="{{menuItem.iconType}}">
          <div *ngFor="let childItem of menuItem.children">
            <li *ngIf="showSubMenuItem(childItem)">
              <app-sidebar-link id="sidebar.{{childItem.title}}" data-cy="sidebar-{{childItem.title}}"
                                route="/{{menuItem.menuPath}}/{{childItem.menuPath}}">
                {{childItem.title}}
              </app-sidebar-link>
            </li>
          </div>
        </app-sidebar-category>
      </ul>
    </div>
  </div>
</dx-scroll-view>
