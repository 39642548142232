import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from './services/web/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  public asyncLoadCount = 0;

  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private renderer: Renderer2,
    private storageService: StorageService
  ) { }

  public ngOnInit(): void {
    const theme = this.storageService.getItem('theme');
    if (theme && theme === 'dark') {
      this.renderer.addClass(document.body, theme);
      this.renderer.addClass(document.body, 'dx-swatch-custom-dark');
    } else {
      this.renderer.addClass(document.body, 'light');
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
