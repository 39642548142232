import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DataMigration} from 'src/app/models/facility/data-migration.model';
import {MigratedFacility} from 'src/app/models/facility/migrated-facility.model';
import {ApiResponseService} from './api-response.service';
import {VisionApiService} from './vision-api.service';

@Injectable({
  providedIn: 'root'
})
export class VisionFacilityService {
  constructor(
    private webApi: VisionApiService,
    private apiResponseService: ApiResponseService
  ) {
  }

  public getMigratedFacilities(): Observable<MigratedFacility[]> {
    return this.webApi.get<MigratedFacility[]>('/facilities/migrated')
      .pipe(catchError(err => {
        this.apiResponseService.showApiErrorOrDefault(err, 'Could not retrieve migrated facilities.');
        return of(undefined);
      }));
  }

  public migrateFacility(migrationRequest: DataMigration): Observable<any> {
    return this.webApi.post('/facilities/migrate', migrationRequest)
      .pipe(catchError(err => {
        this.apiResponseService.showApiErrorOrDefault(err, 'Error migrating facility.');
        return of(undefined);
      }));
  }

  public toggleFacilityActivation(facilityId: number, activated: boolean): Observable<boolean> {
    return this.webApi.post<boolean>(`/facilities/activation/${facilityId}/${activated}`)
      .pipe(catchError(err => {
        this.apiResponseService.showApiErrorOrDefault(err, 'Error toggling facility activation.');
        return of(undefined);
      }));
  }
}
