import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Menu } from 'src/app/models/web/menu.model';
import { PermissionsService } from '../api/permissions.service';
import { StorageService } from '../web/storage.service';
import {FACILITY_ADMIN} from '../../exports/constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuardService {

  constructor(
    private storageService: StorageService,
    private router: Router,
    private menuService: PermissionsService
  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.storageService.getSessionItem('accessToken') && this.storageService.getSessionItem('passwordExpired') === 'false') {
      if (this.storageService.getSessionItem('access')) {
        return this.testAccess(state);
      } else {
        const isFacilityAdmin = this.storageService.getSessionItem('currentFacilityAccess') === FACILITY_ADMIN;
        return this.menuService.getAllMenus(isFacilityAdmin)
          .pipe(switchMap(() => of(this.testAccess(state))));
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  private testAccess(url: RouterStateSnapshot): boolean {
    if (this.hasAccess(url)) {
      return true;
    } else {
      return false;
    }
  }

  private hasAccess(url: RouterStateSnapshot): boolean {
    const target = url.url.split('/');
    const menus: Menu[] = JSON.parse(this.storageService.getSessionItem('access'));
    return menus.some(m => m.menuPath === target[1] && (!m.children || m.children && m.children.some(c => c.menuPath === target[2])));
  }
}

export const dashboardGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => inject(DashboardGuardService).canActivate(next, state);
