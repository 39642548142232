import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private persistentErrorSettings = {
    enableHtml: true,
    extendedTimeOut: 0,
    timeOut: 0
  };

  constructor(private toastr: ToastrService) { }

  public success(message: string, title: string): void {
    this.toastr.success(message, title);
  }

  public error(message: string, title: string): void {
    this.toastr.error(message, title);
  }

  public persistentError(message: string, title: string): void {
    message += '<br /><i>(Click to dismiss)</i>';
    this.toastr.error(message, title, this.persistentErrorSettings);
  }

  public warning(message: string, title: string): void {
    this.toastr.warning(message, title);
  }

  public info(message: string, title: string): void {
    this.toastr.info(message, title);
  }
}
