<div class="sidebar-category" [ngClass]="{'opened': !isCollapsed}">
  <span class="sidebar-category__header" (click)="toggle()">
    <span class="sidebar-category__icon" *ngIf="icon">
      <i class="material-icons">{{iconName}}</i></span>
    <span id="{{id}}" class="sidebar-category__title">
      {{title}}
    </span>
    <span class="sidebar-category__chevron">
      <i class="material-icons">chevron_right</i></span>
  </span>
  <div *ngIf="!isCollapsed">
    <ul class="sidebar-category__submenu">
      <ng-content></ng-content>
    </ul>
  </div>
</div>