import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, expand, map, reduce, skip, take, toArray } from 'rxjs/operators';
import { MAX_PAGINATION_SIZE } from 'src/app/exports/constants';
import { PendingBilling } from 'src/app/models/billing/pending-billing.model';
import { OnSiteStoreDrug } from 'src/app/models/drug/onsite-store-drug.model';
import { MarGroup } from 'src/app/models/facility/mar-group.model';
import { OnsiteStore } from 'src/app/models/facility/onsite-store.model';
import { EdpURIs } from '../../endpoints/edpURIs';
import { VisionURIs } from '../../endpoints/visionURIs';
import { AdditionalOrderMessageField } from '../../models/facility/additional-order-message-field.model';
import { AdminSchedule } from '../../models/facility/admin-schedule.model';
import { BulkFacilityAccess } from '../../models/facility/bulk-facility-access.model';
import { FacilityAccess } from '../../models/facility/facility-access.model';
import { Facility } from '../../models/facility/facility.model';
import { NdcStatusCodes } from '../../models/facility/ndc-status-codes.model';
import { LiteralOrderCategory } from '../../models/literal-order/literal-order-category.model';
import { LiteralOrder } from '../../models/literal-order/literal-order.model';
import { NursingStationLocation } from '../../models/nursing-station/nursing-station-location.model';
import { NursingStation } from '../../models/nursing-station/nursing-station.model';
import { Physician } from '../../models/physician/physician.model';
import { InvoiceGroup } from '../../models/report/invoice/invoice-group.model';
import { UserFacilityAccessMetrics } from '../../models/web/user-facility-access-metrics.model';
import { NotificationService } from '../web/notification.service';
import { ApiResponseService } from './api-response.service';
import { VisionApiService } from './vision-api.service';
import { VisionFacilityService } from './vision-facility.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private api: VisionApiService,
    private notificationService: NotificationService,
    private apiResponseService: ApiResponseService,
    private migratedFacilityService: VisionFacilityService
  ) {
  }

  public getFacility(facilityId: string): Observable<Facility> {
    const queryParams = {
      facilityId: facilityId,
    };

    return this.api.get<Facility>(EdpURIs.facility, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve data for the requested facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getFacilities(includeReadonly: boolean = true): Observable<FacilityAccess[]> {
    return this.api.get<FacilityAccess[]>(EdpURIs.getFacilities)
      .pipe(
        map(allFacilities =>
          includeReadonly ? allFacilities : allFacilities.filter(facility => facility.accessLevel !== 'READ')),
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of facilities.', 'Error');
          return of(undefined);
        })
      );
  }

  public getActivatedFacilities(includeReadonly: boolean = true): Observable<FacilityAccess[]> {
    const activeFacilities$ = this.migratedFacilityService.getMigratedFacilities()
      .pipe(map(facilities => facilities.filter(facility => facility.active === true)));
    const allFacilities$ = this.getFacilities(includeReadonly);

    return forkJoin([activeFacilities$, allFacilities$])
      .pipe(
        map(([active, all]) => all.filter(a => active.some(f => f.facilityId === a.facilityId)))
      );
  }

  public getUsersWithFacilityAccess(facilityId: string): Observable<FacilityAccess[]> {
    const queryParams = {
      facilityId: facilityId,
    };

    return this.api.get<FacilityAccess[]>(EdpURIs.getFacilities, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve users with access to the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getNursingStations(facilityId: string): Observable<NursingStation[]> {
    const queryParams = {
      facilityId: facilityId,
    };

    return this.api.get<NursingStation[]>(EdpURIs.getNursingStations, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of nursing stations within the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public searchPhysicians(lastName: string, facilityId: string, size?: number): Observable<Physician[]> {
    const body = {
      lastName: lastName,
      facilityId: facilityId,
    };

    const queryParams = size !== undefined && !isNaN(size) ? { size: size } : null;

    return this.api.post<Physician[]>(EdpURIs.searchPhysicians, body, true, null, queryParams)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Error searching for physician');
          return of(undefined);
        })
      );
  }

  public getPhysiciansByFacilityIdDataSource(facilityId: string): DataSource {
    return new DataSource({
      pageSize: 50,
      store: new CustomStore({
        key: 'npi',
        load: (loadOptions) => this.searchPhysicians(loadOptions.searchValue?.substring(0, 16), facilityId, loadOptions.skip + loadOptions.take)
            .pipe(
              concatMap(physicians => physicians),
              skip(loadOptions.skip),
              take(loadOptions.take),
              toArray()
            )
            .toPromise(),
        byKey: (npi: string) => {
          if (npi !== '') {
            const queryParams = {
              npi: npi
            };
            return this.api.get<Physician>(EdpURIs.prescribers, queryParams).toPromise();
          };
        }
      })
    });
  }

  public getLiteralOrderCategories(facilityId: string): Observable<LiteralOrderCategory[]> {
    const queryParams = {
      facilityId: facilityId,
    };

    return this.api.get<LiteralOrderCategory[]>(EdpURIs.getLiteralOrderCategories, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the categories for literal orders.', 'Error');
          return of(undefined);
        })
      );
  }

  public getLiteralOrderLibrary(facilityId: string, literalCategoryCode: string): Observable<LiteralOrder[]> {
    const queryParams = {
      facilityId: facilityId,
      literalCategoryCode: literalCategoryCode
    };

    return this.api.get<LiteralOrder[]>(EdpURIs.getLiteralOrderLibrary, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the literal order library.', 'Error');
          return of(undefined);
        })
      );
  }

  public getAdminTimes(facilityId: string, nursingStationId?: string): Observable<AdminSchedule[]> {
    const queryParams = {
      facilityId: facilityId,
    };

    if (nursingStationId) {
      queryParams['nursingStationId'] = nursingStationId;
    }

    return this.api.get<AdminSchedule[]>(EdpURIs.getAdminTimes, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of admin schedules within the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getFacilitiesByName(facName: string): Observable<Facility[]> {
    const pathParams = {
      facName: facName
    };
    return this.api.get(VisionURIs.getFacilitiesByName, null, true, pathParams);
  }

  public grantFacilityAccess(accessModel: FacilityAccess): Observable<any> {

    return this.api.post(EdpURIs.grantFacilityAccess, accessModel)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not grant facility access to user.', 'Error');
          return of(undefined);
        })
      );
  }

  public grantBulkFacilityAccess(accessModel: BulkFacilityAccess): Observable<any> {

    return this.api.post(EdpURIs.grantBulkFacilityAccess, accessModel)
      .pipe(
        catchError((error) => {
          this.notificationService.error('Could not grant bulk facility access to user.', 'Error');
          return of(undefined);
        })
      );
  }

  public revokeFacilityAccess(facilityId: string, userId: string): Observable<any> {
    const body = {
      facilityId: facilityId,
      userId: userId
    };

    return this.api.post(EdpURIs.revokeFacilityAccess, body)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not revoke facility access.', 'Error');
          return of(undefined);
        })
      );
  }

  public getFacilityUserAccessMetrics(): Observable<UserFacilityAccessMetrics[]> {
    return this.api.get<UserFacilityAccessMetrics[]>(EdpURIs.getFacilityUserAccessMetrics)
      .pipe(
        catchError(() => {
          this.notificationService.error('Facility User Metrics could not be returned.', 'Error');
          return of(undefined);
        })
      );
  }

  public getRooms(facilityId: string, nursingStationId: string): Observable<NursingStationLocation[]> {
    const queryParams = {
      facilityId: facilityId,
      nursingStationId: nursingStationId
    };

    return this.api.get<NursingStationLocation[]>(EdpURIs.getRooms, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of rooms within nursing station.', 'Error');
          return of(undefined);
        })
      );
  }

  public getBeds(facilityId: string, nursingStationId: string, room: string): Observable<NursingStationLocation[]> {
    const queryParams = {
      facilityId: facilityId,
      nursingStationId: nursingStationId,
      roomId: room
    };

    return this.api.get<NursingStationLocation[]>(EdpURIs.getBeds, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of beds within the room.', 'Error');
          return of(undefined);
        })
      );
  }

  public getAllPendingBilling(facilityId: string, patientId: string, invoiceGroupId: string): Observable<PendingBilling[]> {
    const queryParams = {
      facilityId: facilityId,
      page: 0,
      size: MAX_PAGINATION_SIZE,
    };

    if (patientId) {
      queryParams['patientId'] = patientId;
    }

    if (invoiceGroupId) {
      queryParams['invoiceGroup'] = invoiceGroupId;
    }

    return this.api.getPagination<PendingBilling[]>(EdpURIs.getPendingBilling, queryParams)
      .pipe(
        expand(res => res.nextPage != null ? this.api.getPagination<PendingBilling[]>(res.nextPage.replace(/.*?\/api/, '')) : EMPTY),
        map(x => x.content),
        reduce((acc: PendingBilling[], curr: PendingBilling[]) => acc.concat(curr)),
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of pending billing records for the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getInvoiceGroups(facilityId: string): Observable<InvoiceGroup[]> {
    const queryParams = {
      facilityId: facilityId
    };

    return this.api.get<InvoiceGroup[]>(EdpURIs.getInvoiceGroups, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve the list of invoice groups for the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getMarGroups(facilityId: string): Observable<MarGroup[]> {
    const queryParams = {
      facilityId: facilityId
    };

    return this.api.get<MarGroup[]>(EdpURIs.getMarGroups, queryParams)
      .pipe(
        catchError(() => {
          this.notificationService.error('Could not retrieve mar groups for the facility.', 'Error');
          return of(undefined);
        })
      );
  }

  public getOnsiteStoreInventory(facilityId: string, ossId: number): Observable<OnSiteStoreDrug[]> {
    const queryParams = {
      facilityId: facilityId,
      ossId: ossId
    };

    return this.api.get<OnSiteStoreDrug[]>(EdpURIs.getOnsiteStoreInventory, queryParams)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, `Could not retrieve on site store inventory '${ossId}' for facility.`);
          return of(undefined);
        })
      );
  }

  public getOnsiteStores(facilityId: string): Observable<OnsiteStore[]> {
    const queryParams = {
      facilityId: facilityId
    };

    return this.api.get<OnsiteStore[]>(EdpURIs.getOnsiteStores, queryParams)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not retrieve on site stores for facility.');
          return of(undefined);
        })
      );
  }

  public getAdditionalOrderMessageFields(facilityId: string): Observable<AdditionalOrderMessageField[]> {
    const queryParams = {
      facilityId: facilityId
    };

    return this.api.get<AdditionalOrderMessageField[]>(VisionURIs.additionalOrderMessageFields, queryParams)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not retrieve additional order message fields for facility.');
          return of(undefined);
        })
      );
  }

  public addAdditionalOrderMessageFields(field: any): Observable<AdditionalOrderMessageField> {

    return this.api.post<AdditionalOrderMessageField>(VisionURIs.additionalOrderMessageFields, field)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not create an additional order message field.');
          return of(undefined);
        })
      );
  }

  public updateAdditionalOrderMessageFields(field: AdditionalOrderMessageField): Observable<AdditionalOrderMessageField> {

    return this.api.put<AdditionalOrderMessageField>(VisionURIs.additionalOrderMessageFields, field)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not update the additional order message field.');
          return of(undefined);
        })
      );
  }

  public deleteAdditionalOrderMessageFields(fieldId: number): Observable<any> {
    const queryParams = {
      fieldId: fieldId.toString()
    };

    return this.api.delete(VisionURIs.additionalOrderMessageFields, null, true, queryParams)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not delete additional order message field.');
          return of(undefined);
        })
      );
  }

  public getNdcStatusCodes(facilityId: string): Observable<NdcStatusCodes> {
    const queryParams = {
      facilityId: facilityId
    };

    return this.api.get<NdcStatusCodes>(EdpURIs.facilityNdcStatusCodes, queryParams)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not retrieve NDC status codes for the facility.');
          return of(undefined);
        })
      );
  }

  public saveNdcStatusCodes(statusCodes: NdcStatusCodes): Observable<any> {

    return this.api.post(EdpURIs.facilityNdcStatusCodes, statusCodes)
      .pipe(
        catchError((err) => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not save NDC status codes for the facility.');
          return of(undefined);
        })
      );
  }
}
