export class IamURIs {
  // Users
  public static mfaTotp = '/frameworkiam/v1/users/mfa/totp';
  public static updateUserProfile = '/frameworkiam/v1/users/profile';

  /**
   * Below is our deprecated way of defining endpoints.  We now prefer to write out the full endpoint as above for
   * improved readability and documentation. All variable elements of a URI should be passed as query parameters
   * to the HttpService. See README in this directory for more info on standards and best practices.
   * **/

  //Base
  public static iamBase = (version: string = '1') => `/frameworkiam/v${version}`;

  // Reset Password
  public static resetPassword = (version: string = '1') => `${IamURIs.iamBase(version)}/users/password`;

  // Tokens
  public static getToken = (version: string = '2') => `${IamURIs.iamBase(version)}/users/token`;

}
