import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { Facility } from 'src/app/models/facility/facility.model';
import { IamURIs } from '../../endpoints/iamURIs';
import { VisionURIs } from '../../endpoints/visionURIs';
import { ResetPasswordModel } from '../../models/web/reset-password.model';
import { SecuritySettings } from '../../models/web/security-settings.model';
import { ApiResponseService } from '../api/api-response.service';
import { FacilityService } from '../api/facility.service';
import { HttpService } from '../api/http.service';
import { VisionApiService } from '../api/vision-api.service';
import { InactivityService } from './inactivity.service';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public facilityId = new BehaviorSubject<string>(this.storageService.getSessionItem('facilityId'));
  public username = this.storageService.getItem('username');
  private facilityInfo = new ReplaySubject<Facility>(1);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private inactivityService: InactivityService,
    private facilityService: FacilityService,
    private apiResponseService: ApiResponseService,
    private notificationService: NotificationService,
    private api: VisionApiService,
    protected httpService: HttpService,
    ) {
    }

  public get userId(): string {
    return this.storageService.getSessionItem('userId');
  }

  public get pharmacyId(): string {
    return this.storageService.getSessionItem('pharmId');
  }

  public changeFacility(facilityId: string): void {
    this.facilityService.getFacility(facilityId)
      .subscribe(facility => {
        this.storageService.setSessionItem('pharmId', facility.pharmacyId);
        this.storageService.setSessionItem('facilityId', facilityId);
        this.facilityId.next(facilityId);
        this.facilityInfo.next(facility);
      });
  }

  public getCurrentFacility(): Observable<string> {
    return this.facilityId.asObservable();
  }

  public getCurrentFacilityValue(): string {
    return this.facilityId.value;
  }

  public getCurrentFacilityInfo(): Observable<Facility> {
    return this.facilityInfo.asObservable();
  }

  public login(username: string, password: string): Observable<any> {
    const body: object = {
      username,
      password,
      grantType: 'password'
    };
    return this.api.post(VisionURIs.getAuthToken, body, false);
  }

  public loginOtp(mfaToken: string, otp: string, trustDevice: boolean): Observable<any> {
    const body: object = {
      mfaToken,
      otp,
      grantType: 'http://auth0.com/oauth/grant-type/mfa-otp',
      trustDevice: trustDevice
    };

    return this.api.post(VisionURIs.getAuthToken, body, false);
  }

  public resetPassword(body: ResetPasswordModel): Observable<any> {
    return this.api.put(IamURIs.resetPassword(), body)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, 'Could not reset password');
          return of(err);
        })
      );
  }


  public initializeTokensOnResponse(response: any, username: string, rememberUser: any): void {
    let remember: boolean;
    if (typeof rememberUser === 'string') {
      remember = rememberUser === 'true';
    } else {
      remember = rememberUser;
    }

    this.storageService.setSessionItem('userId', response.userId);
    this.storageService.setSessionItem('accessToken', response.accessToken);
    this.storageService.setSessionItem('refreshToken', response.refreshToken);
    this.storageService.setSessionItem('iamBaseUrl', response.iamBaseUrl);
    this.storageService.setItem('remember', remember ? 'true' : 'false');

    if (remember) {
      this.storageService.setItem('username', username);
    } else {
      this.storageService.removeItem('username');
    }

    this.updateInactivityTimeout();
  }

  public refresh(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.getSessionItem('refreshToken')}`)
    };
    return this.httpService.post<any>(this.api.getBaseApiPath() + VisionURIs.getRefreshAuthToken, httpOptions.headers).pipe(
      tap(result => {
        this.mapTokenResponse(result);
      }, error => {
        console.error(error);
      })
    );
  }

  private mapTokenResponse(response: any): void {
    //Save updated tokens and userId to session storage
    this.storageService.setSessionItem('userId', response.userId);
    this.storageService.setSessionItem('accessToken', response.accessToken);
    this.storageService.setSessionItem('refreshToken', response.refreshToken);
  }

  private updateInactivityTimeout(): void {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${this.storageService.getSessionItem('accessToken')}`);

    const options = {
      headers
    };

    this.api.get<SecuritySettings>('/frameworkiam/v1/securitypolicies', options)
      .pipe(first())
      .subscribe(
        res => {
          this.storageService.setSessionItem('inactivityTimeout', res.inactivityTimerMinutes.toString());
          this.inactivityService.restartInactivityTimer();
        }
      );
  }
}
