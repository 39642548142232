import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, forkJoin, of, shareReplay } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { FacilityAccess } from 'src/app/models/facility/facility-access.model';
import { Menu } from 'src/app/models/web/menu.model';
import { FacilityService } from 'src/app/services/api/facility.service';
import { AuthService } from 'src/app/services/web/auth.service';
import { NotificationService } from 'src/app/services/web/notification.service';
import { StorageService } from 'src/app/services/web/storage.service';
import { FACILITY_ADMIN } from '../../../../exports/constants';
import { PermissionsService } from '../../../../services/api/permissions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public showMobileNavigation = false;
  @Output() public closeMobileNavigation = new EventEmitter();
  @ViewChild('mobileSidebar') public mobileSidebarElement: ElementRef;
  @ViewChild('mobileSidebarMenu') public mobileSidebarMenuElement: ElementRef;
  public menuItems: any[] = [];
  public selectedFacility: FacilityAccess;
  public facilities: FacilityAccess[];
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private menuService: PermissionsService,
    private authService: AuthService,
    private facilityService: FacilityService,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) { }

  public ngOnInit(): void {
    this.initializeComponentsDataStreams();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngAfterViewInit(): void {
    this.mobileSidebarElement.nativeElement.addEventListener('click', (event) => {
      if (!this.mobileSidebarMenuElement.nativeElement.contains(event.target)) {
        this.closeMobileNavigation.emit();
      }
    });
  }

  public onFacilityChange(facility: FacilityAccess): void {
    this.authService.changeFacility(facility.facilityId);
    this.selectedFacility = facility;
    this.storageService.setSessionItem('currentFacilityAccess', facility.accessLevel);
    this.refreshMenus();
    this.router.navigate(['dashboard']).then();
  }

  private initializeComponentsDataStreams(): void {
    const activeFacilities$ = this.facilityService.getActivatedFacilities();
    const currentFacility$ = this.authService.getCurrentFacility().pipe(first());

    //Get Active Facilities and Current Facility ONCE
    const facilitiesInfo$ = forkJoin([currentFacility$, activeFacilities$])
      .pipe(shareReplay({bufferSize: 1, refCount:true}));

    //Set cached values based on results of active facilities and current facility
    facilitiesInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([cachedId, facilities]) => {
        this.facilities = facilities;
        this.selectedFacility = this.facilities.find(f => f.facilityId === cachedId) || this.facilities[0];
        if(this.selectedFacility){
          this.authService.changeFacility(this.selectedFacility.facilityId);
          this.storageService.setSessionItem('currentFacilityAccess', this.selectedFacility.accessLevel);
        }
      });

    //Fetch menu items AFTER getting active facilities and current facility (THIS HAS TO BE DONE SEQUENTIALLY BECAUSE WE NEED THE CURRENT FACILITY ACCESS)
    facilitiesInfo$
      .pipe(
        map(([cachedId, facilities]) => facilities.find(f => f.facilityId === cachedId) || (this.facilities[0] ?? {accessLevel:''} as unknown as FacilityAccess)),
        switchMap(facAccess => this.menuService.getAllMenus(facAccess.accessLevel === FACILITY_ADMIN)),
        catchError(err => {
          this.notificationService.error('A problem occurred while loading the menus', 'Error');
          return of(undefined);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe( (routesList: Menu[]): void => {
        this.menuItems = routesList || [];
      });
  }

  private refreshMenus(): void {
    this.menuService.getAllMenus(this.storageService.getSessionItem('currentFacilityAccess') === FACILITY_ADMIN)
      .pipe(
        catchError(err => {
          this.notificationService.error('A problem occurred while loading the menus', 'Error');
          return of(undefined);
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        (routesList: Menu[]): void => {
          this.menuItems = routesList || [];
      });
  }
}
