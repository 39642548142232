import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from './session.service';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private sessionService: SessionService
  ) {
  }

  public logout(): void {
    this.logUserSession();
    this.storageService.clearSession();
    this.router.navigate(['login']);
  }

  private logUserSession(): void {
    this.sessionService.logUserSession(
      +this.storageService.getSessionItem('sessionId'),
      this.storageService.getSessionItem('userId'),
      this.storageService.getSessionItem('ipAddress'),
      'LOGOUT'
    ).subscribe(rtn => this.storageService.setSessionItem('sessionId', rtn.id));
  }
}
