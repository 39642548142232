import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/api/user.service';
import { NotificationService } from 'src/app/services/web/notification.service';
import { ValidationService } from 'src/app/services/web/validation.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  @Input() public modal: ModalComponent;
  public changePasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  public ngOnInit(): void {
    this.createForms();
  }

  private createForms(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.changePasswordForm.get('confirmPassword').setValidators([Validators.required, ValidationService.validatePasswordsMatch.bind(this, this.changePasswordForm.get('newPassword'))]);
  }

  public changePassword(): void {
    this.userService.changePassword(
      this.changePasswordForm.get('currentPassword').value,
      this.changePasswordForm.get('newPassword').value,
      this.changePasswordForm.get('confirmPassword').value)
      .subscribe(res => {
        if (res !== undefined) {
          this.notificationService.success('Password changed successfully.', 'Success');
          this.close();
        }
      });
  }

  public close(): void {
    this.modal.closeModal();
    this.changePasswordForm.reset();
  }
}