<div #modal class="modal" [ngStyle]="{'display': showModal ? 'flex' : 'none'}">
  <div #modalBody class="modal__body" [ngClass]="{'modal__body--lg' : size === 'body_lg', 'modal__body--md' : size === 'body_md'}">
    <div class="modal__icon modal__icon--danger" *ngIf="danger">
      <img id="modal.exclamation" src="assets/icons/exclamation.svg" alt="Exclamation">
    </div>
    <div class="modal__content">
      <div class="margin-bottom">
        <div class="modal__header" [ngStyle]="{'margin-bottom': subHeader ? '10px' : '0px'}">{{header}}</div>
        <div id="modal.close" class="modal__close" (click)="closeModal()">&times;</div>
        <p class="modal__caption">{{subHeader}}</p>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
