import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NotificationService} from '../web/notification.service';
import {VisionApiService} from './vision-api.service';
import {catchError, tap} from 'rxjs/operators';
import {AuthService} from '../web/auth.service';
import {StorageService} from '../web/storage.service';
import {MediprocityUser} from '../../models/mediprocity/mediprocity-user.model';
import {MediprocityApiService} from './mediprocity-api.service';
import {UnreadMessagesResponseModel} from '../../models/mediprocity/unread-messages-response.model';
import {MediprocityToken} from '../../models/mediprocity/mediprocity-token.model';

@Injectable({
  providedIn: 'root'
})
export class MediprocityService {
  constructor(
    private visionApi: VisionApiService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private storageService: StorageService,
    private mediprocityApi: MediprocityApiService
  ) {
  }

  public get username(): string {
    return this.storageService.getSessionItem('mediUsername');
  }

  public getLinkedMediprocityCredentials(): Observable<MediprocityUser>{
    return this.visionApi.get<MediprocityUser>(`/mediprocity/credentials/${this.authService.userId}`)
      .pipe(
        tap(res => {
          this.storageService.setSessionItem('mediUsername', res.mediUsername);
          this.storageService.setSessionItem('mediAccessToken', res.mediAccessToken);
          this.storageService.setSessionItem('mediReloadWidget', 'true');
        }),
        catchError(error => {
          this.storageService.setSessionItem('mediUsername', '');
          this.storageService.setSessionItem('mediAccessToken', '');
          this.storageService.setSessionItem('mediReloadWidget', 'true');
          return of(undefined);
        }),
      );
  }

  public linkMediprocityAccount(username: string, password: string, encryptionKey: string): Observable<any> {
    const body = {
      userid: this.authService.userId,
      username: username,
      password: password,
      encryptionKey: encryptionKey
    };

    return this.visionApi.post<MediprocityToken>('/mediprocity/linkmediprocity', body)
      .pipe(
        tap(res => {
          this.storageService.setSessionItem('mediUsername', username);
          this.storageService.setSessionItem('mediAccessToken', res.token);
          this.storageService.setSessionItem('mediReloadWidget', 'true');
          this.notificationService.success('Mediprocity Credentials Successfully Linked', 'Success');
        }),
        catchError(error => {
          if (error.status === 404) {
            this.notificationService.error('Failed to Authenticate Mediprocity Credentials. Verify username, password, and encryption key are correct.', 'Error');
          } else {
            this.notificationService.error(error as string, 'Error');
          }
          return of(undefined);
        }),
      );
  }

  public getUnreadMessages(): Observable<UnreadMessagesResponseModel> {
    return this.mediprocityApi.get<UnreadMessagesResponseModel>('/v1/unread_messages_count')
      .pipe(
        catchError((error) => {
          return of(undefined);
        })
      );
  }

  public deleteLinkedAccount(): Observable<any> {
    return this.visionApi.delete(`/mediprocity/credentials/${this.authService.userId}`)
      .pipe(
        tap(res => {
          this.storageService.setSessionItem('mediUsername', '');
          this.storageService.setSessionItem('mediAccessToken', '');
          this.storageService.setSessionItem('mediReloadWidget', 'true');
          this.notificationService.success('Mediprocity Account Deleted', 'Success');
        }),
        catchError(error => {
          if (error.status === 404) {
            this.notificationService.error('No Linked Account Found To Delete', 'Error');
          } else {
            this.notificationService.error(error as string, 'Error');
          }
          return of(undefined);
        }),
      );
  }
}
