import { Injectable } from '@angular/core';
import { VisionApiService } from './vision-api.service';
import { ApiResponseService } from './api-response.service';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApplicationSetting } from 'src/app/models/site-customization/application-setting.model';
import { VisionURIs } from 'src/app/endpoints/visionURIs';
import { ImageEnabledResponse } from 'src/app/models/site-customization/image-enabled-response.model';

@Injectable({
  providedIn: 'root'
})
export class SiteCustomizeService {
  constructor(
    private webApi: VisionApiService,
    private apiResponseService: ApiResponseService
  ) { }

  public getApplicationSetting(key: string): Observable<ApplicationSetting> {
    const pathParams = { key };
    return this.webApi.get<ApplicationSetting>(VisionURIs.siteCustomizationApplicationSettings, null, true, pathParams)
      .pipe(
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error getting application setting: ${key}`);
          return of(undefined);
        })
      );
  }

  public setApplicationSetting(key: string, value: string): Observable<boolean> {
    const pathParams = { key };
    const body = { value };
    return this.webApi.put(VisionURIs.siteCustomizationApplicationSettings, body, true, pathParams)
      .pipe(
        map(res => true),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error setting application setting: ${key}`);
          return of(false);
        })
      );
  }

  public getCustomImage(key: string): Observable<string|ArrayBuffer> {
    const pathParams = { key };
    return this.webApi.getBlob(VisionURIs.siteCustomizationImages, null, true, pathParams)
      .pipe(
        switchMap(res => from(this.wrapReadAsDataUrlInPromise(res))),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error getting image: ${key}`);
          return of(undefined);
        })
      );
  }

  public getCustomImageEnabled(key: string): Observable<boolean> {
    const pathParams = { key };
    return this.webApi.get<ImageEnabledResponse>(VisionURIs.siteCustomizationImageEnabled, null, true, pathParams)
      .pipe(
        map(res => res.enabled),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error fetching if image is enabled: ${key}`);
          return of(undefined);
        })
      );
  }

  public enableCustomImage(key: string): Observable<boolean> {
    const pathParams = { key };
    return this.webApi.put(VisionURIs.siteCustomizationImageEnable, null, true, pathParams)
      .pipe(
        map(res => true),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error enabling image: ${key}`);
          return of(false);
        })
      );
  }

  public disableCustomImage(key: string): Observable<boolean> {
    const pathParams = { key };
    return this.webApi.put(VisionURIs.siteCustomizationImageDisable, null, true, pathParams)
      .pipe(
        map(res => true),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error disabling image: ${key}`);
          return of(false);
        })
      );
  }

  public resetDefaultImage(key: string): Observable<boolean> {
    const pathParams = { key };
    return this.webApi.put(VisionURIs.siteCustomizationImageResetDefault, null, true, pathParams)
      .pipe(
        map(res => true),
        catchError(err => {
          this.apiResponseService.showApiErrorOrDefault(err, `Error resetting default image: ${key}`);
          return of(false);
        })
      );
  }

  private wrapReadAsDataUrlInPromise(blob: Blob): Promise<string | ArrayBuffer> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(undefined);
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(blob);
    });
  }
}
