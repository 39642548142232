import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FunctionalityPermission } from '../../models/role/functionality-permission.model';
import { PaginationResult } from '../../models/web/pagination-result.model';
import { InactivityService } from '../web/inactivity.service';
import { NotificationService } from '../web/notification.service';
import { StorageService } from '../web/storage.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {
  protected basePath = '';
  protected get headers() {
    return this.createHeaders();
  }

  constructor(
    protected httpService: HttpService,
    protected inactivityService: InactivityService,
    protected storageService: StorageService,
    private notificationService: NotificationService
  ) {
  }

  public getPagination<T>(url: string, params: any = null): Observable<PaginationResult<T>> {
    return this.checkPermission(url, 'get')
      ? this.httpService.getPagination<T>(this.fullUrl(url), this.headers, params)
      : of(undefined);
  }

  public get<T>(url: string, params: any = null): Observable<T> {
    return this.checkPermission(url, 'get')
      ? this.httpService.get<T>(this.fullUrl(url), this.headers, params)
      : of(undefined);
  }

  public getBlob(url: string, params: any = null): Observable<Blob> {
    return this.checkPermission(url, 'get')
      ? this.httpService.getBlob(this.fullUrl(url), this.headers, params)
      : of(undefined);
  }

  public postPagination<T>(url: string, body: any = null, params: any = null): Observable<PaginationResult<T>> {
    return this.checkPermission(url, 'post')
      ? this.httpService.postPagination<T>(this.fullUrl(url), this.headers, body, params)
      : of(undefined);
  }

  public post<T>(url: string, body: any = null, params: any = null): Observable<T> {
    return this.checkPermission(url, 'post')
      ? this.httpService.post<T>(this.fullUrl(url), this.headers, body, params)
      : of(undefined);
  }

  public put<T>(url: string, body: any = null): Observable<T> {
    return this.checkPermission(url, 'put')
      ? this.httpService.put<T>(this.fullUrl(url), this.headers, body)
      : of(undefined);
  }

  public patch<T>(url: string, body: any = null): Observable<T> {
    return this.checkPermission(url, 'patch')
      ? this.httpService.patch<T>(this.fullUrl(url), this.headers, body)
      : of(undefined);
  }

  public delete<T>(url: string, body: any = null, params: any = null): Observable<T> {
    return this.checkPermission(url, 'delete')
      ? this.httpService.delete<T>(this.fullUrl(url), this.headers, body, params)
      : of(undefined);
  }

  public postDownload<T>(url: string, body: any = null): Observable<T> {
    return this.checkPermission(url, 'post')
      ? this.httpService.postDownload(this.fullUrl(url), this.headers, body)
      : of(undefined);
  }

  public checkPermission(url: string, verb: string): boolean {
    let perms: string;
    switch (verb.toLowerCase()) {
      case 'get':
        perms = this.storageService.getSessionItem('getPermissions');
        break;
      case 'post':
        perms = this.storageService.getSessionItem('postPermissions');
        break;
      case 'delete':
        perms = this.storageService.getSessionItem('deletePermissions');
        break;
      case 'put':
        perms = this.storageService.getSessionItem('putPermissions');
        break;
      case 'patch':
        perms = this.storageService.getSessionItem('patchPermissions');
        break;
    }

    const strippedUrl = url.split('?')[0];
    const perm = perms ? (JSON.parse(perms) as FunctionalityPermission[]).filter(f => f.endpoint === strippedUrl) : undefined;
    if (perm && perm.length > 0 && !perm[0].permissionGranted) {
      this.notificationService.warning(
        `You have not been granted permission to perform the action '${perm[0].name}'. Please contact your administrator.`,
        'Permissions');
      return false;
    }
    else {
      return true;
    }
  }

  protected createHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  protected fullUrl(url: string): string {
    return this.basePath + url;
  }
}
