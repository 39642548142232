import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from 'src/app/services/web/validation.service';

@Component({
  selector: 'app-control-messages',
  template: `<div *ngIf="errorMessage !== null">{{errorMessage}}</div>`,
  styleUrls: ['./control-messages.component.scss']
})

export class ControlMessagesComponent {
  @Input() public control: FormControl;
  @Input() public errorMessageOverride: string;
  @Input() public showBeforeTouch: boolean = false;

  get errorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.showBeforeTouch || this.control.touched)) {
        return this.errorMessageOverride ?? ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }

    return null;
  }
}
