import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { PaginationResult } from '../../models/web/pagination-result.model';
import { StringUtils } from '../utilities/string-utils.service';
import { ConfigService } from '../web/config.service';
import { InactivityService } from '../web/inactivity.service';
import { NotificationService } from '../web/notification.service';
import { StorageService } from '../web/storage.service';
import { ApiBaseService } from './api-base.service';
import { HttpService } from './http.service';
import { UriUtils } from '../utilities/uri-utils.service';

@Injectable({
  providedIn: 'root'
})
export class VisionApiService extends ApiBaseService {
  protected refreshEndpoint = '';
  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private configService: ConfigService,
    httpService: HttpService,
    inactivityService: InactivityService,
    storageService: StorageService,
    notificationService: NotificationService
  ) {
    super(httpService, inactivityService, storageService, notificationService);
    const baseUrlOverride = this.configService.getConfig().webApiPath;
    this.basePath = StringUtils.isNullUndefinedOrWhitespace(baseUrlOverride)
      ? baseUrl
      : baseUrlOverride;
    this.refreshEndpoint = this.basePath + '/frameworkiam/v1/users/refresh';
  }

  public getBaseApiPath(): string {
    return this.basePath;
  }

  public getPagination<T>(url: string, queryParams: any = null, wrapInRefresh: boolean = true, pathParams: any = null): Observable<PaginationResult<T>> {
    this.inactivityService.restartInactivityTimer();
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.getPagination<T>(url, queryParams);
    return wrapInRefresh
      ? this.wrapInRefresh<PaginationResult<T>>(baseStream, url, 'getPagination', queryParams)
      : baseStream;
  }

  public get<T>(url: string, queryParams: any = null, wrapInRefresh: boolean = true, pathParams: any = null): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    //ToDo: Probably should move all path param logic to base api service so any api service can use it
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.get<T>(url, queryParams);
    return wrapInRefresh
      ? this.wrapInRefresh<T>(baseStream, url, 'get', queryParams)
      : baseStream;
  }

  public getBlob(url: string, queryParams: any = null, wrapInRefresh: boolean = true, pathParams: any = null): Observable<any> {
    this.inactivityService.restartInactivityTimer();
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.getBlob(url, queryParams);
    return wrapInRefresh
      ? this.wrapInRefresh<any>(baseStream, url, 'get', queryParams)
      : baseStream;
  }

  public postPagination<T>(url: string, body: any = null, wrapInRefresh: boolean = true, pathParams: any = null, queryParams: any = null): Observable<PaginationResult<T>> {
    this.inactivityService.restartInactivityTimer();
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.postPagination<T>(url, body, queryParams);
    return wrapInRefresh
      ? this.wrapInRefresh<PaginationResult<T>>(baseStream, url, 'postPagination', null, body)
      : baseStream;
  }

  public post<T>(url: string, body: any = null, wrapInRefresh: boolean = true, pathParams: any = null, queryParams: any = null): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.post<T>(url, body, queryParams);
    return wrapInRefresh
      ? this.wrapInRefresh<T>(baseStream, url, 'post', null, body)
      : baseStream;
  }

  public put<T>(url: string, body: any = null, wrapInRefresh: boolean = true, pathParams: any = null): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    url = UriUtils.addPathParamsToURI(url, pathParams);

    const baseStream = super.put<T>(url, body);
    return wrapInRefresh
      ? this.wrapInRefresh<T>(baseStream, url, 'put', null, body)
      : baseStream;
  }

  public patch<T>(url: string, body: any = null, wrapInRefresh: boolean = true): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    const baseStream = super.patch<T>(url, body);
    return wrapInRefresh
      ? this.wrapInRefresh<T>(baseStream, url, 'patch', null, body)
      : baseStream;
  }

  public delete<T>(url: string, body: any = null, wrapInRefresh: boolean = true, params: any = null): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    const baseStream = super.delete<T>(url, body, params);
    return wrapInRefresh
      ? this.wrapInRefresh<T>(baseStream, url, 'delete', null, body)
      : baseStream;
  }

  public postDownload<T>(url: string, body: any = null, wrapInRefresh: boolean = true): Observable<T> {
    this.inactivityService.restartInactivityTimer();
    const baseStream = super.postDownload<T>(url, body);
    return wrapInRefresh
      ? this.wrapInRefresh(baseStream, url, 'postDownload', null, body)
      : baseStream;
  }

  public wrapInRefresh<T>(stream: Observable<T>, url: string, verb: string, params: any = null, body: any = null): Observable<T> {
    return stream.pipe(
      catchError(error => {
        // Unauthorized request, refresh the access token and try again
        if (error.status === 401 && !StringUtils.isNullUndefinedOrWhitespace(this.refreshEndpoint)) {
          return this.refreshToken<T>(url, verb, params, body);
        }
        return throwError(error);
      })
    );
  }

  protected createHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${this.storageService.getSessionItem('accessToken')}`);
    return headers;
  }

  private refreshToken<T>(url: string, verb: string, params: any, body: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${this.storageService.getSessionItem('refreshToken')}`);

    return this.httpService.post(this.refreshEndpoint, headers, null)
      .pipe(
        map((res: any): HttpHeaders => this.initializeTokensOnResponse(res)),
        concatMap((refreshedHeaders: HttpHeaders) => {
          switch (verb) {
            case 'getPagination':
              return this.httpService.getPagination<T>(this.fullUrl(url), refreshedHeaders, params);
            case 'get':
              return this.httpService.get<T>(this.fullUrl(url), refreshedHeaders, params);
            case 'postPagination':
              return this.httpService.postPagination<T>(this.fullUrl(url), refreshedHeaders, body);
            case 'post':
              return this.httpService.post<T>(this.fullUrl(url), refreshedHeaders, body);
            case 'put':
              return this.httpService.put<T>(this.fullUrl(url), refreshedHeaders, body);
            case 'patch':
              return this.httpService.patch<T>(this.fullUrl(url), refreshedHeaders, body);
            case 'delete':
              return this.httpService.delete<T>(this.fullUrl(url), refreshedHeaders, body, params);
            case 'postDownload':
              return this.httpService.postDownload<T>(this.fullUrl(url), refreshedHeaders, body);
          }
        }),
        catchError(error => throwError(error))
      );
  }

  private initializeTokensOnResponse(response: any): HttpHeaders {
    //Save updated tokens and userId to session storage
    this.storageService.setSessionItem('userId', response.userId);
    this.storageService.setSessionItem('accessToken', response.accessToken);
    this.storageService.setSessionItem('refreshToken', response.refreshToken);

    //Create new HttpHeaders with new access token
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${response.accessToken}`);
    return headers;
  }
}
