import {Inject, Injectable, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {HttpService} from '../api/http.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from './config.service';
import {StringUtils} from '../utilities/string-utils.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  public inactivityTimeout = 60;
  private inactivityTimer: any;
  private millisecondMultiplier = 60000;

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private configService: ConfigService,
    private router: Router,
    private storageService: StorageService,
    private ngZone: NgZone,
    private httpService: HttpService,
    private deviceService: DeviceDetectorService,
  ) { }

  public restartInactivityTimer(): void {
    clearInterval(this.inactivityTimer);
    const sessionInactivityTimeout = parseInt(this.storageService.getSessionItem('inactivityTimeout'), 10);
    if (sessionInactivityTimeout) {
      this.inactivityTimeout = parseInt(this.storageService.getSessionItem('inactivityTimeout'), 10);
    }

    // We run this outside angular in order to prevent errors with Protractor tests
    this.ngZone.runOutsideAngular(() =>
      this.inactivityTimer = setInterval(() => this.logOut(), this.inactivityTimeout * this.millisecondMultiplier)
    );
  }

  public logOut(): void {
    this.logUserSession();
    this.storageService.clearSession();
    this.ngZone.run(() => this.router.navigateByUrl('/login'));
  }

  /*
   Cannot use Vision Api service or any service that uses it (i.e. Session Service) to log the user session
   in the Inactivity Service or we will cause a circular dependency (The Vision API service  depends
   on the Inactivity Service for now).
  */
  private logUserSession(): void {
    const body = {
      sessionId: this.storageService.getSessionItem('sessionId'),
      userId: this.storageService.getSessionItem('userId'),
      ipAddress: this.storageService.getSessionItem('ipAddress'),
      deviceType: this.deviceService.os_version,
      browser: this.deviceService.browser,
      eventType: 'INACTIVITY'
    };

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${this.storageService.getSessionItem('accessToken')}`);

    const baseUrlOverride = this.configService.getConfig().webApiPath;
    const sessionUrl = (StringUtils.isNullUndefinedOrWhitespace(baseUrlOverride)
      ? this.baseUrl
      : baseUrlOverride) + '/users/usersession';

    this.httpService.post(sessionUrl, headers, body)
      .pipe(catchError(err => of(undefined)))
      .subscribe((resp: any) => this.storageService.setSessionItem('sessionId', resp?.id));
  }
}
