import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-category',
  templateUrl: './sidebar-category.component.html',
  styleUrls: ['./sidebar-category.component.scss']
})

export class SidebarCategoryComponent implements OnInit {
  @Input() public icon = '';
  @Input() public title = '';
  @Input() public id = '';
  public iconName;
  public isCollapsed = true;

  constructor() {}

  public ngOnInit(): void {
    this.iconName = this.icon;
  }

  public toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
