import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  private appConfig: any;

  constructor(private http: HttpClient) { }

  public async loadAppConfig(): Promise<void> {
    const url = environment.appConfigPath;
    const data = await this.http.get(url).toPromise();
    this.appConfig = data;
  }

  public getConfig(): any {
    return this.appConfig;
  }
}
