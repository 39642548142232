import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, ValidationErrors } from '@angular/forms';
import { MAX_SQL_DATE, MIN_SQL_DATE } from 'src/app/exports/constants';
import { StringUtils } from '../utilities/string-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const config = {
      required: 'Required',
      whitespace: 'Required',
      invalidDate: 'Invalid date',
      invalidStartDate: 'Start date must be less than end date',
      invalidEmailAddress: 'Invalid email address',
      invalidSocialSecurity: 'Invalid social security number',
      invalidPhoneNumber: 'Invalid phone number',
      invalidZipCode: 'Invalid zip code',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      min: `Minimal value ${validatorValue.min}`,
      max: `Maximum value ${validatorValue.max}`,
      invalidPasswordMatch: 'Passwords do not match',
      futureDate: 'Date in future'
    };
    return config[validatorName];
  }

  public static dateValidator(control: AbstractControl): ValidationErrors {
    // A valid date will be considered one that fits within SQL Server
    const date = new Date(control.value);
    if (date <= MAX_SQL_DATE && date >= MIN_SQL_DATE) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }

  public static optionalDateValidator(control: AbstractControl): ValidationErrors {
    if (control.value === '') {
      return null;
    } else {
      return ValidationService.dateValidator(control);
    }
  }

  public static startDateValidator(startDateControl: FormControl, endDateControl: FormControl): ValidationErrors {
    const startDate = new Date(startDateControl.value);
    const endDate = new Date(endDateControl.value);

    if (StringUtils.isNullUndefinedOrWhitespace(startDateControl.value)
      || StringUtils.isNullUndefinedOrWhitespace(endDateControl.value)
      || startDate <= endDate) {
      return null;
    } else {
      return { invalidStartDate: true };
    }
  }

  public static emailValidator(control: AbstractControl): ValidationErrors {
    // RFC 2822 compliant regex
    // eslint-disable-next-line max-len
    if (control.value && (control.value.match(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/) || control.value.match(/^\s*$/))) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  public static socialSecurityValidator(control: AbstractControl): ValidationErrors {
    if (!control.value || control.value.match(/(^\d{3}-?\d{2}-?\d{4}$)/) || control.value.match(/^\s*$/)) {
      return null;
    } else {
      return { invalidSocialSecurity: true };
    }
  }

  public static socialSecurityWithMaskValidator(control: AbstractControl): ValidationErrors {
    if (!control.value || control.value.match(/(^[0-9*]{3}[-*]?[0-9*]{2}[-*]?\d{4}$)/) || control.value.match(/^\s*$/)) {
      return null;
    } else {
      return { invalidSocialSecurity: true };
    }
  }

  public static phoneNumberValidator(control: AbstractControl): ValidationErrors {
    // eslint-disable-next-line max-len
    if (!control.value || control.value.match(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/) || control.value.match(/^\s*$/)) {
      return null;
    } else {
      return { invalidPhoneNumber: true };
    }
  }

  public static zipCodeValidator(control: AbstractControl): ValidationErrors {
    if (!control.value || control.value.match(/^\d{5}$/) || control.value.match(/^\d{9}$/) || control.value.match(/^\s*$/)) {
      return null;
    } else {
      return { invalidZipCode: true };
    }
  }

  public static passwordValidator(control: AbstractControl): ValidationErrors {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  public static validatePasswordsMatch(fieldControl: FormControl, fieldControlToMatch: FormControl): ValidationErrors {
    return fieldControl.value === fieldControlToMatch.value
      ? null
      : { invalidPasswordMatch: true };
  }

  public static atleastOneSelectedValidator(control: FormArray): ValidationErrors {
    if (control.controls.some(c => c.value === true)) {
      return null;
    } else {
      return { required: true };
    }
  }

  public static controlGreaterThanOtherControl(smallerControl: FormControl, largerControl: FormControl): ValidationErrors {
    return +smallerControl.value > +largerControl.value ? {smallerControlLarger: true} : null;
  }

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  public static futureDateValidator(futureDateControl: FormControl): ValidationErrors {
    const dateSelected = new Date(futureDateControl.value);
    const todaysDate = new Date();

    if (dateSelected <= todaysDate) {
      return null;
    } else {
      return { futureDate: true };
    }
  }
}
