<div class="topbar">
  <div class="row row--align-center">
    <button class="sidenav-button full-height" type="button" (click)="showMobileNavigation.emit()">
      <img [src]="icon" alt="" class="icon" />
    </button>
    <a routerLink="/dashboard">
      <div class="logo-container" *ngIf="(loadingCustomizations$ | async) === false">
        <img class="logo" [src]="topBarLogoPath" title="Top Bar Logo" alt="Top Bar Logo"/>
      </div>
    </a>
  </div>
  <div class="row row--align-center margin-right--lg">
    <div class="widget" *ngIf="showMediprocityMailbox$ | async">
      <div *ngIf="unreadMessages > 0" class="{{unreadMessages > 9 ? 'badge badge__multiple' : 'badge badge__single'}}">
        {{unreadMessages > 100 ? '100+' : unreadMessages }}</div>
      <span class="dx-icon-message icon" (click)="openMediprocity()"></span>
    </div>
    <i class="fa fa-sun-o theme" (click)="toggleTheme('light')" *ngIf="theme === 'dark'"></i>
    <i class="fa fa-moon-o theme" (click)="toggleTheme('dark')" *ngIf="theme === 'light'"></i>
    <app-dropdown [alignLeft]="false">
      <ng-container header>
        <div class="profile-dropdown">
          <span id="dropdown.profile-name" data-cy="dropdown-profile-name" class="profile-dropdown__name"
            *ngIf="(user$ | async) as user">
            {{user.firstName}} {{user.lastName}}
          </span>
          <i class="material-icons">expand_more</i>
        </div>
      </ng-container>
      <ng-container body>
        <div class="dropdown-body">
          <a target="_blank" [href]="iamPortalLink" class="dropdown-body__item">
            <span>Open IAM Portal</span>
          </a>
          <div class="dropdown-body__item" (click)="changePassword()">
            <span>Change Password</span>
          </div>
          <div class="dropdown-body__item" *ngIf="advancedLicense" (click)="linkMediprocity()">
            <span>Mediprocity Link</span>
          </div>
          <div class="dropdown-body__item" (click)="userGuide()">
            <span>User Guide</span>
          </div>
          <div>
            <a class="dropdown-body__item" href="https://support.frameworkltc.com/" target="_blank">SoftWriters Support</a>
          </div>
          <div id="dropdown.signout-button" data-cy="dropdown-signout-button" class="dropdown-body__item"
            (click)="logout()">
            <span>Sign Out</span>
          </div>
        </div>
      </ng-container>
    </app-dropdown>
  </div>
</div>
<app-modal #passwordModal header="Change password" [showModal]="showChangePassword" (modalClose)="modalClosed()">
  <app-change-password [modal]="passwordModal"></app-change-password>
</app-modal>
<app-modal #mediprocityLinkModal header="Mediprocity Link" [showModal]="showMediprocityLink"
  (modalClose)="modalClosed()">
  <app-mediprocity-link [modal]="mediprocityLinkModal"></app-mediprocity-link>
</app-modal>
<app-modal #mediprocityWidgetModal header="Mediprocity" [showModal]="showMediprocityWidget" size="body_lg"
  (modalClose)="modalClosed()">
  <app-mediprocity-widget [modal]="mediprocityWidgetModal" [triggerReload]="triggerWidgetReload">
  </app-mediprocity-widget>
</app-modal>
