import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {VisionApiService} from '../api/vision-api.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private visionApi: VisionApiService,
    private deviceService: DeviceDetectorService,
  ) { }

  public logUserSession(sessionId: number, userId: string, ipAddress: string, eventType: string): Observable<any> {
    const body = {
      sessionId,
      userId,
      ipAddress,
      deviceType: this.deviceService.os_version,
      browser: this.deviceService.browser,
      eventType
    };

    return this.visionApi.post(`/users/usersession`, body)
      .pipe(catchError(err => of(undefined)));
  }
}
