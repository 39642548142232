<form [formGroup]="changePasswordForm">
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        Current password
        <input id="change-password.current-password" type="password" class="input-container__field"
          formControlName="currentPassword">
        <app-control-messages id="change-password.current-password-required" class="input-container__error"
          [control]="changePasswordForm.get('currentPassword')">
        </app-control-messages>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        New password
        <input id="change-password.new-password" type="password" class="input-container__field"
          formControlName="newPassword">
        <app-control-messages id="change-password.new-password-required" class="input-container__error"
          [control]="changePasswordForm.get('newPassword')">
        </app-control-messages>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="input-container input-container--lg">
      <label class="input-container__label">
        Confirm password
        <input id="change-password.confirm-password" type="password" class="input-container__field"
          formControlName="confirmPassword">
        <app-control-messages id="change-password.confirm-password-required" class="input-container__error"
          [control]="changePasswordForm.get('confirmPassword')">
        </app-control-messages>
      </label>
    </div>
  </div>
</form>
<div class="row row--end">
  <button id="change-password.close" class="btn btn--tertiary" (click)="close()">
    Close
  </button>
  <button id="change-password.change" class="btn btn--primary" [ngClass]="{'disabled': !changePasswordForm.valid}"
    [disabled]="!changePasswordForm.valid" (click)="changePassword()">
    Change password
  </button>
</div>