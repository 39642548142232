import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { MainLayoutModule } from './modules/main-layout/main-layout.module';
import { ConfigService } from './services/web/config.service';

const appInitializerFn = (configService: ConfigService) => async () => await configService.loadAppConfig();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(routes, {}),
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MainLayoutModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 5,
      timeOut: 5000,
      extendedTimeOut: 2000,
      newestOnTop: false
    })
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService]
    },
    DeviceDetectorService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() { }
}
