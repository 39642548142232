import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '../web/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ApiResponseService {

  constructor(private notificationService: NotificationService) {}

  public setErrorMessages(response: any): string[] {
    const modelState = response?.error?.modelState;

    return this.convertModelStateToErrorList(modelState);
  }

  public setVisionApiErrorMessages(response: any): string[] {
    const modelState = response?.error?.errors ?? response?.error;

    return this.convertModelStateToErrorList(modelState);
  }

  public showApiErrorOrDefault(response: HttpErrorResponse, defaultError: string): void {
    const apiError = this.appendModelStateErrors(response)?.replace(/\./g, '.<br /><br />') ?? defaultError;
    this.notificationService.persistentError(apiError, 'Error');
  }

  private appendModelStateErrors(response: HttpErrorResponse): string {
    const error = response?.error?.message;

    if (error) {
      // Append EDP model errors if they exist
      const modelStateErrors = this.setErrorMessages(response);
      return error + modelStateErrors.join('.');
    } else if (response?.error?.errors || response?.error) {
      // Return Vision model errors if they exist
      return this.setVisionApiErrorMessages(response).join('');
    }

    return null;
  }

  private convertModelStateToErrorList(modelState: any): string[] {
    const errorMessages = [];

    if (modelState) {
      Object.keys(modelState).forEach(errorKey => {
        const errorValue = (modelState[errorKey]);
        if (Array.isArray(errorValue)) {
          for (const error of errorValue) {
            errorMessages.push(error);
          }
        } else {
          errorMessages.push(errorValue);
        }
      });
    }

    return errorMessages;
  }
}
