<div #mobileSidebar class="mobile-sidebar" [ngClass]="{'mobile-sidebar--visible': showMobileNavigation}">
  <div #mobileSidebarMenu class="mobile-sidebar__menu">
    <app-sidebar-menu 
      [menuItems]="menuItems"
      [facilities]="facilities"
      [selectedFacility]="selectedFacility"
      (facilityChange)="onFacilityChange($event)">
    </app-sidebar-menu>
    <span class="mobile-sidebar__close" data-cy="mobile-sidebar__close" (click)="closeMobileNavigation.emit()">&times;</span>
  </div>
</div>
<div class="sidebar">
  <app-sidebar-menu
    [menuItems]="menuItems"
    [facilities]="facilities"
    [selectedFacility]="selectedFacility"
    (facilityChange)="onFacilityChange($event)">
  </app-sidebar-menu>
</div>