import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesComponent } from 'src/app/modules/shared/components/control-messages/control-messages.component';
import { CardMessagesComponent } from './components/card-messages/card-messages.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DaySelectorComponent } from './components/day-selector/day-selector.component';
import { DropdownListItemComponent } from './components/dropdown-list/dropdown-list-item.component';
import { DropdownListComponent } from './components/dropdown-list/dropdown-list.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EditableCardComponent } from './components/editable-card/editable-card.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { ModalComponent } from './components/modal/modal.component';
import { PrintViewComponent } from './components/print-view/print-view.component';
import { SelectableCardComponent } from './components/selectable-card/selectable-card.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import {DxDateBoxModule, DxSelectBoxModule} from 'devextreme-angular';
import {MediprocityLinkComponent} from './components/mediprocity-link/mediprocity-link.component';
import {MediprocityWidgetComponent} from './components/mediprocity-widget/mediprocity-widget.component';
import {AddOrderMsgFieldsComponent} from './components/add-order-msg-fields/add-order-msg-fields.component';
import {EnableMfaComponent} from './components/enable-mfa/enable-mfa.component';
import {SubPageNavigationComponent} from './components/sub-page-navigation/sub-page-navigation.component';

@NgModule({
  declarations: [
    CardMessagesComponent,
    ControlMessagesComponent,
    DaySelectorComponent,
    DateRangeComponent,
    DropdownComponent,
    DropdownListComponent,
    DropdownListItemComponent,
    EditableCardComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    PrintViewComponent,
    SelectableCardComponent,
    TabNavigationComponent,
    ChangePasswordComponent,
    EnableMfaComponent,
    MediprocityLinkComponent,
    MediprocityWidgetComponent,
    AddOrderMsgFieldsComponent,
    SubPageNavigationComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalModule,
        DxDateBoxModule,
        DxSelectBoxModule
    ],
  exports: [
    CardMessagesComponent,
    ControlMessagesComponent,
    DaySelectorComponent,
    DateRangeComponent,
    DropdownComponent,
    DropdownListComponent,
    DropdownListItemComponent,
    EditableCardComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    PrintViewComponent,
    SelectableCardComponent,
    TabNavigationComponent,
    ChangePasswordComponent,
    EnableMfaComponent,
    MediprocityLinkComponent,
    MediprocityWidgetComponent,
    AddOrderMsgFieldsComponent,
    SubPageNavigationComponent
  ],
})
export class SharedModule { }
