export class VisionURIs {
  // Authorization
  public static getAuthToken = '/authorization/token';
  public static getRefreshAuthToken = '/authorization/refreshToken';

  //Drugs
  public static drugQuotes = '/drugQuotes';
  public static drugQuoteType = '/drugQuotes/setup/inactiveTypes';
  public static getDrugsForFacility = '/drugQuotes/forFacility';

  //EdpSync
  public static syncAdditionalOrderMessageFields = '/edpSync/additionalOrderMessageFields';

  //Eula
  public static checkForEula = '/eula/check/{userId}';
  public static getEula = '/eula';
  public static acceptEula = '/eula/accept';

  //Facilities
  public static getFacilitiesByName = '/facilities/{facName}';
  public static additionalOrderMessageFields = '/facilities/options/additionalOrderMessageFields';

  //Functionality
  public static functionalityPermissions = '/functionality/permissions';

  //Pages
  public static getAllMenus = '/pages/get/{isFacilityAdmin}';

  //Roles
  public static lookupRolesForUsers = '/roles/lookupForUsers';

  //Site Customization
  public static siteCustomizationImages = '/siteCustomize/images/{key}';
  public static siteCustomizationImageResetDefault = '/siteCustomize/images/{key}/resetDefault';
  public static siteCustomizationImageEnabled = '/siteCustomize/images/{key}/enabled';
  public static siteCustomizationImageEnable = '/siteCustomize/images/{key}/enable';
  public static siteCustomizationImageDisable = '/siteCustomize/images/{key}/disable';
  public static siteCustomizationApplicationSettings = '/siteCustomize/applicationSettings/{key}';

  //Encryption
  public static encrypt = '/appsettings/encrypt';

  //App settings
  public static iamBaseUrl = '/appsettings/iamBaseUrl';

  /**
   * Below is our deprecated way of defining endpoints.  We now prefer to write out the full endpoint as above for
   * improved readability and documentation. All variable elements of a URI should be passed as query parameters
   * to the HttpService. See README in this directory for more info on standards and best practices.
   * **/

  //Roles
  public static functionalityPermissionsByRole = (roleId: string) => `/roles/${roleId}/functionality`;

  // Facilities
  public static deleteAdditionalOrderMessageFields = (fieldId: string) => `/facilities/options/additionalOrderMessageFields?fieldId=${fieldId}`;
}
