export class EdpURIs {
  //Auth
  public static nursingStations = '/edp/v1/auth/nursingStations';
  public static nursingStationsAccess = '/edp/v1/auth/nursingStations/access';
  public static getFacilities = '/edp/v1/auth/facilities';
  public static grantFacilityAccess = '/edp/v1/auth/facilities/grant';
  public static grantBulkFacilityAccess = '/edp/v2/auth/facilities/grant';
  public static revokeFacilityAccess = '/edp/v1/auth/facilities/revoke';

  //Drugs
  public static destroyDrugExternal = '/edp/v1/drugs/logs/disposal/external';
  public static destroyDrugInternal = '/edp/v1/drugs/logs/disposal/internal';
  public static getAllCompounds = '/edp/v1/drugs/compounds';
  public static getDispensesByGpi = '/edp/v1/drugs/dispenses/byGpi';
  public static getDisposalLog = '/edp/v1/drugs/logs/disposal';
  public static getDrugQuote = '/edp/v1/drugs/drugQuote';
  public static getDrugs = '/edp/v1/drugs/search';
  public static getGpiGroups = '/edp/v1/drugs/gpiDrugGroups';

  //Facilities
  public static facility = '/edp/v1/facilities';
  public static getNursingStations = '/edp/v1/facilities/nursingStations';
  public static getAdminTimes = '/edp/v1/facilities/adminTimes';
  public static getFacilityUserAccessMetrics = '/edp/v1/metrics/facilities/access';
  public static getRooms = '/edp/v1/facilities/rooms';
  public static getBeds = '/edp/v1/facilities/beds';
  public static getPendingBilling = '/edp/v1/billing/pending';
  public static getInvoiceGroups = '/edp/v1/billing/invoices/groups';
  public static getMarGroups = '/edp/v1/facilities/marGroups';
  public static getOnsiteStoreInventory = '/edp/v1/facilities/onSiteStores/Inventory';
  public static getOnsiteStores = '/edp/v1/facilities/onSiteStores';
  public static facilityNdcStatusCodes = '/edp/v1/facilities/options/ndcStatusCodes';
  public static facilityOptions = '/edp/v1/facilities/options';

  //Monographs
  public static monographByNdc = '/edp/v1/monographs/byNdc';
  public static monograph = '/edp/v1/monographs';

  //MOPs
  public static getResidentInsurancePlans = '/edp/v1/mops/census';

  //Orders
  public static order = '/edp/v1/orders';
  public static getAdminTypes = '/edp/v1/orders/sigCodeAdministrationTypes';
  public static upsertLiteralOrder = '/edp/v1/orders/literal/patient';
  public static getOrderByRxNo = '/edp/v2/orders/orderByRxNo';
  public static getFillInformation = '/edp/v1/orders/fillInformation';
  public static getLiteralOrderCategories = '/edp/v1/orders/literal/categoryCodes';
  public static getLiteralOrderLibrary = '/edp/v1/orders/literal/library';
  public static getResidentOrders = '/edp/v1/orders/ordersByPatient';

  //Pharmacy
  public static getPharmacies = '/edp/v1/pharmacies';
  public static getPharmacyNDCStatusCodes = '/edp/v1/pharmacies/options/ndcStatusCodes';

  //Prescribers
  public static prescribers = '/edp/v1/prescribers';
  public static prescribersBatched = '/edp/v1/prescribers/batch';
  public static searchPhysicians = '/edp/v1/prescribers/search';

  //Reports
  public static getPatientLiteralsByCategoryReport = '/edp/v1/reports/patientLiteralOrdersByCategory';
  public static getAntimicrobialDaysOfTherapyReport = '/edp/v1/reports/antimicrobialDaysOfTherapy';
  public static getLeaveOfAbsenceReport = '/edp/v1/reports/leaveOfAbsence';
  public static getBlackBoxWarnings = '/edp/v1/reports/blackBoxWarnings';
  public static getBlackBoxWarningsSortOrder = '/edp/v1/reports/blackBoxWarnings/sortOrder';
  public static getReportFormatCodes = '/edp/v1/medicalrecords/formats';
  public static getDrugInteractionReport = '/edp/v2/reports/drugInteraction';
  public static reportFormatsByFacility = '/edp/v1/medicalrecords/formats/facility';

  //Resident
  public static resident = '/edp/v1/patients';
  public static getResidentSearch = '/edp/v1/patients/search';
  public static getResidentAllergies = '/edp/v1/patients/allergies';
  public static residentAllergies = '/edp/v1/patients/allergy';
  public static getResidentMedicalConditions = '/edp/v1/patients/medicalConditions';
  public static addMedicalCondition = '/edp/v1/patients/medicalCondition';
  public static getResidentLiteralOrders = '/edp/v1/patients/literalOrders';
  public static dischargeResident = '/edp/v1/patients/queueDischargePatient';
  public static transferResident = '/edp/v1/patients/transfer';
  public static updateDemographics = '/edp/v1/patients/update';
  public static readmitResident = '/edp/v1/patients/readmit';
  public static deleteInsurancePlan = '/edp/v1/patients/plans';
  public static getMedicareACoverage = '/edp/v1/patients/medicareACoverage';
  public static postRetrieveResidentBatch = '/edp/v1/patients/batch';

  //Returns
  public static orderReturns = '/edp/v1/orders/returns';

  //Utilities
  public static systemOptions = '/edp/v1/utilities/systemOptions';
  public static drugInteractionHistory = '/edp/v1/utilities/drugInteraction/history';
  public static drugInteractionHistoryDetails = '/edp/v1/drugInteractions/history/{id}';

  //Returns (Updated definition)
  public static orderReturnsPackingLabels = '/edp/v1/orders/returns/packingLabels';


  /**
   * Below is our deprecated way of defining endpoints.  We now prefer to write out the full endpoint as above for
   * improved readability and documentation. All variable elements of a URI should be passed as query parameters
   * to the HttpService. See README in this directory for more info on standards and best practices.
   * **/

  //Base
  public static edpBase = (version: string = '1') => `/edp/v${version}`;

  //Additional Order Message Fields
  // public static additionalOrderMessageFields = '/edp/v1/facilities/options/additionalOrderFields';
  // public static getAdditionalOrderMessageFields = (facilityId: string) => `${EdpURIs.additionalOrderMessageFields}?facilityId=${encodeURIComponent(facilityId)}`;
  // public static deleteAdditionalOrderMessageFields = (fieldId: number, facilityId: string) =>
  //   `${EdpURIs.additionalOrderMessageFields}?fieldId=${encodeURIComponent(fieldId)}&facilityId=${encodeURIComponent(facilityId)}`;

  //Returns
  public static orderReturnsInquiry = (facilityId: string, startDate: string, endDate: string) =>
    `${EdpURIs.orderReturns}/inquiry?facilityId=${encodeURIComponent(facilityId)}&startDate=${startDate}&endDate=${endDate}`;
  public static orderReturnsPending = () => `${EdpURIs.orderReturns}/pending`;
  public static orderReturnsProcess = () => `${EdpURIs.orderReturnsPending()}/process`;
  public static getOrderReturnsPending = (facilityId: string) => `${EdpURIs.orderReturnsPending()}?facilityId=${encodeURIComponent(facilityId)}`;
  public static orderReturnsPendingInternal = () => `${EdpURIs.orderReturnsPending()}/internal`;
  public static deleteOrderReturnsPendingInternal = (facilityId: string, itemId: number) =>
    `${EdpURIs.orderReturnsPendingInternal()}?facilityId=${encodeURIComponent(facilityId)}&itemId=${encodeURIComponent(itemId)}`;
  public static orderReturnsPendingExternal = () => `${EdpURIs.orderReturnsPending()}/external`;
  public static deleteOrderReturnsPendingExternal = (facilityId: string, itemId: number) =>
    `${EdpURIs.orderReturnsPendingExternal()}?facilityId=${encodeURIComponent(facilityId)}&itemId=${encodeURIComponent(itemId)}`;

  //Medical Report Endpoints
  public static medicalRecords = () => `${EdpURIs.edpBase()}/medicalrecords`;
  public static dischargeRecord = () => `${EdpURIs.medicalRecords()}/Discharge`;
  public static treatmentSheet = () => `${EdpURIs.medicalRecords()}/treatmentSheet`;
  public static marReport = () => `${EdpURIs.medicalRecords()}/mar`;
  public static assistedDailyLivingReport = () => `${EdpURIs.medicalRecords()}/assistedDailyLiving`;
  public static physicianOrderForm = () => `${EdpURIs.medicalRecords()}/physiciansOrder`;
  public static physicianOrderFormSortOrder = () => `${EdpURIs.physicianOrderForm()}/sortOrders`;
  public static physicianProgressNotes = () => `${EdpURIs.medicalRecords()}/physicianProgressNotes`;
  public static antipsychoticRecord = () => `${EdpURIs.medicalRecords()}/antipsychoticRecord`;
  public static nursingFlowRecord = () => `${EdpURIs.medicalRecords()}/nursingFlowRecord`;
  public static returnPackingLabel = () => `${EdpURIs.medicalRecords()}/returnPackingLabel`;
}
