import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '../modal/modal.component';
import {MediprocityService} from '../../../../services/api/mediprocity.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StorageService} from '../../../../services/web/storage.service';

@Component({
  selector: 'app-mediprocity-link',
  templateUrl: './mediprocity-link.component.html',
  styleUrls: ['mediprocity-link.component.scss']
})
export class MediprocityLinkComponent implements OnInit, OnDestroy {
  @Input() public modal: ModalComponent;
  public mediprocityLinkForm: FormGroup;
  public linkedUsername: string;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private mediprocityService: MediprocityService,
    private storageService: StorageService
  ) { }

  public ngOnInit(): void {
    this.createForms();
    this.linkedUsername = this.storageService.getSessionItem('mediUsername');
  }

  public linkMediprocity(): void {
    this.mediprocityService.linkMediprocityAccount(
      this.mediprocityLinkForm.get('username').value,
      this.mediprocityLinkForm.get('password').value,
      this.mediprocityLinkForm.get('encryptionKey').value
    ).pipe(takeUntil(this.ngUnsubscribe)).subscribe( res => {
      if(res){
        this.close();
      }
    });
  }

  public close(): void {
    this.modal.closeModal();
    this.mediprocityLinkForm.reset({});
    this.linkedUsername = this.storageService.getSessionItem('mediUsername');
  }

  public deleteLinkedAccount(): void{
    this.mediprocityService.deleteLinkedAccount().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.close();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private createForms(): void {
    this.mediprocityLinkForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      encryptionKey: ['', [Validators.required]],
    });
  }
}
