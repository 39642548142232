import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { indicate } from 'src/app/exports/operators';
import { User } from 'src/app/models/user/user.model';
import { SiteCustomizeService } from 'src/app/services/api/site-customize.service';
import { UserService } from 'src/app/services/api/user.service';
import { LogoutService } from 'src/app/services/web/logout.service';
import { StorageService } from 'src/app/services/web/storage.service';
import { ThemeService } from 'src/app/services/web/theme.service';
import { environment } from '../../../../../environments/environment';
import { BURGER_SVG_ICON } from '../../../../exports/constants';
import { MediprocityService } from '../../../../services/api/mediprocity.service';
import { ReportViewerService } from '../../../../services/web/report-viewer.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit, OnDestroy {
  public icon = BURGER_SVG_ICON;
  public user$: Observable<User>;
  public showChangePassword = false;
  public showSecondConfirmation = false;
  public showMediprocityLink = false;
  public showMediprocityWidget = false;
  public showMediprocityMailbox$: Subject<boolean>;
  public triggerWidgetReload = false;
  public userGuidePath = environment.userGuidePath;
  public topBarLogoPath: string | ArrayBuffer;
  public unreadMessages: number;
  public advancedLicense = false;
  public loadingCustomizations$ = new BehaviorSubject<boolean>(true);
  public iamPortalLink = '';
  @Output() public showMobileNavigation = new EventEmitter();

  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private storageService: StorageService,
    private userService: UserService,
    private logoutService: LogoutService,
    private reporterViewer: ReportViewerService,
    private mediprocityService: MediprocityService,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private siteCustomizeService: SiteCustomizeService,
  ) { }

  public get theme(): string {
    return this.storageService.getItem('theme');
  }

  public ngOnInit(): void {
    this.loadCustomizations();
    this.user$ = this.userService.getUserById(this.storageService.getSessionItem('userId'));
    this.advancedLicense = this.storageService.getSessionItem('advancedLicense') === 'true';

    //Set IAM portal url
    let iamUrl = this.storageService.getSessionItem('iamBaseUrl');
    iamUrl = iamUrl?.endsWith('/') ? iamUrl : `${iamUrl}/`;
    this.iamPortalLink = `${iamUrl}IAMPortal/login`;

    const unreadMessage$ = timer(0, 30000).pipe(
      switchMap(_ => this.mediprocityService.getUnreadMessages())
    );

    this.showMediprocityMailbox$ = new BehaviorSubject<boolean>(false);
    this.showMediprocityMailbox$
      .pipe(
        switchMap(result => {
          if(result){
            return unreadMessage$;
          }
          return of(undefined);
        }),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(unreadMessages => {
      this.unreadMessages = unreadMessages ? unreadMessages.unread_messages_count : 0;
    });
    this.showMailbox();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public logout(): void {
    this.logoutService.logout();
  }

  public modalClosed(): void {
    this.showChangePassword = false;
    this.showMediprocityLink = false;
    this.showMediprocityWidget = false;
    this.showSecondConfirmation = false;
    this.showMailbox();
  }

  public changePassword(): void {
    this.showChangePassword = true;
  }

  public linkMediprocity(): void {
    this.showMediprocityLink = true;
  }

  public openMediprocity(): void {
    this.showMediprocityWidget = true;
    this.triggerWidgetReload = this.storageService.getSessionItem('mediReloadWidget') === 'true';
  }

  public userGuide(): void {
    this.reporterViewer.openWindow(this.userGuidePath);
  }

  public toggleTheme(theme: string): void {
    if (theme === 'light') {
      this.renderer.addClass(document.body, 'light');
      this.renderer.addClass(document.body, 'dx-swatch-custom-light');
      this.renderer.removeClass(document.body, 'dark');
      this.renderer.removeClass(document.body, 'dx-swatch-custom-dark');
      this.storageService.setItem('theme', 'light');
      this.themeService.setTheme('light');
    } else {
      this.renderer.addClass(document.body, 'dark');
      this.renderer.addClass(document.body, 'dx-swatch-custom-dark');
      this.renderer.removeClass(document.body, 'light');
      this.renderer.removeClass(document.body, 'dx-swatch-custom-light');
      this.storageService.setItem('theme', 'dark');
      this.themeService.setTheme('dark');
    }
  }

  private showMailbox(){
    if (this.advancedLicense && this.storedCredentials()) {
      this.showMediprocityMailbox$.next(true);
    }
    else{
      this.showMediprocityMailbox$.next(false);
    }
  }

  private storedCredentials(): boolean {
    const username = this.storageService.getSessionItem('mediUsername');
    const token = this.storageService.getSessionItem('mediAccessToken');
    return !!(username && token);
  }

  private loadCustomizations() {
    this.siteCustomizeService.getCustomImage('TopBarLogo')
      .pipe(
        indicate(this.loadingCustomizations$),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => this.topBarLogoPath = res);
  }
}
